import firebase from 'firebase';
import {IS_PROD} from "../constants/environment";

console.log(IS_PROD);

const firebaseConfig = IS_PROD ? {
        apiKey: "AIzaSyDXedKG4ts5rwmpygZUnzruYYGwxdHKyxg",
        authDomain: "togo-a2894.firebaseapp.com",
        databaseURL: "https://togo-a2894.firebaseio.com",
        projectId: "togo-a2894",
        storageBucket: "togo-a2894.appspot.com",
        messagingSenderId: "1069041587578",
        appId: "1:1069041587578:web:833b623562f328cfc832c3"
    }
    :
    {
        apiKey: "AIzaSyD12F_SEIQFo0dFKrsaN6-wUsZY0m5ngU4",
        authDomain: "mentoroid-dev.firebaseapp.com",
        databaseURL: "https://mentoroid-dev.firebaseio.com",
        projectId: "mentoroid-dev",
        storageBucket: "mentoroid-dev.appspot.com",
        messagingSenderId: "1094401664380",
        appId: "1:1094401664380:web:5ea053d996a4f4b00f30f9",
    }

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
