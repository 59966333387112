import {Checkbox, FormControlLabel} from '@material-ui/core';
import React from 'react';

export default function CheckboxControl({
                                            label,
                                            checked,
                                            onChange,
                                            disabled = false,
                                            ...checkboxProps
                                        }) {
    return (
        <FormControlLabel
            key={label}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    {...checkboxProps}
                    disabled={disabled}
                />
            }
            label={label}
        />
    );
}
