import React from 'react'
import ScenarioPage from './ScenarioPage/ScenarioPage'
import {AppBar, Tab, Tabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import BannerPopup from './BannerPopupPage/BannerPopup';
import {Page} from "./DataPage/Page";
import BillingTypePage from "./BillingTypePage";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        paddingTop: "60px"
    },
}));

export default () => {
    const [value, setValue] = React.useState(2)
    const classes = useStyles();

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="データ構造"/>
                    <Tab label="シナリオ"/>
                    <Tab label="両方"/>
                    <Tab label="バナー&ポップアップ"/>
                    <Tab label="課金種別"/>
                </Tabs>
            </AppBar>
            <div className={'flex flex-row gap-1 justify-center w-full'}>
                {
                    value === 0 || value === 2 ?
                        // <Grid item xs={value === 2 ? 6 : 12}>
                        <div className={'basis-1/3 flex flex-1 overflow-auto'}>
                            <Page/>
                        </div>
                        // </Grid>
                        : null
                }
                {
                    value === 1 || value === 2 ?
                        // <Grid item xs={value === 2 ? 6 : 12}>
                        <div className={'basis-2/3'}>
                            <ScenarioPage/>
                        </div>
                        // </Grid>
                        : null
                }
                {value === 3 && <div className={`basis-2/3`}>
                    <BannerPopup/>
                </div>}

                {value === 4 && <div className={`w-full`}>
                    <BillingTypePage/>
                </div>}

            </div>
        </div>
    )
}
