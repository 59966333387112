import React from "react";

export class NormalButton extends React.Component<{
    text: any;
    onClick: any;
    className?: string;
    isUploading?: boolean;
    styles?: string,
}> {
    render() {
        let {text, onClick, className, isUploading, styles} = this.props;
        return (
            <button
                className={`text-white cursor-pointer hover:bg-black bg-gray-600 text-white rounded border-none p-2 ${
                    className ?? ""
                }`}
                onClick={onClick}
                style={{width: styles}}
            >
                {isUploading ? "読み込み中..." : text}
            </button>
        );
    }
}
