import React, {useEffect, useMemo, useRef, useState} from "react";
import {Grid, MenuItem} from "@material-ui/core";
import axios from "axios";
import {imageStorage} from "../../firebase/store";
import {
    BlockExitImageItem,
    chatgptItem,
    ChoiceItem,
    DelayItem,
    EnecolorItem,
    ForEndItem,
    ForStartItem,
    GraphItem,
    IfEndItem,
    IfStartItem,
    ImageItem,
    InputItem,
    MotionItem,
    RecordEndItem,
    RecordStartItem,
    SlideItem,
    TextItem,
} from "./Items";
import {
    AutoSaveCheckbox,
    DataStructureView,
    DeployButton,
    LanguageSelector,
    MentaroidView,
    MetadataView,
    ScenarioSelector,
} from "./Views";
import {
    BuilderSelector,
    ControlBuilder,
    EnecolorBuilder,
    GraphBuilder,
    ImageBuilderImageItem,
    ImageBuilderImageList,
    ImageBuilderUploader,
    InputBuilder,
    MotionBuilder,
    SlideBuilder,
    TextBuilderList,
} from "./Builders";
import {useAtom} from "jotai";
import {lastScenarioAtom} from "../../atoms/lastScenario";
import {firebaseFunc} from "../../utils/functions";
import {scenarioListAtom} from "../../atoms/scenarioList.atom";
import firebase from "firebase";
import {IMAGES, THUMB, THUMBYTUBE} from "../../constants/folder";
import uniqid from "uniqid";
import {v4 as uuidv4} from 'uuid';
import {useModal} from "../../hooks/useModal";
import DeleteModal from "../../component/DeleteModal";
import toast from "react-hot-toast";
import {useScenarios} from "../../hooks/scenarios";
import {find, isFunction} from "lodash";
import ChatgptComponent from "./ChatgptComponent";
import useCheckedDefinitions from "../../hooks/useCheckedDefinitions";
import {ScenarioContentBuilder} from "./ScenarioContentBuilder";

export const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

// a little function to help us with reordering the result
export const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;
export let autoSaveFlag = false;

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#FDF" : "white",
    position: "relative",
    // styles we need to apply on draggables
    ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "#EEE",
    padding: grid,
});

export interface ItemArg {
    name: string;
    target_id: string;
    rank?: number;
    groups?: string[];
}

export interface EneColorGroup {
    name: string,
    url: string,
}

export interface EneColorItemArg {
    target_id: string;
    groupsImg: EneColorGroup[];
    rank: number | null;
    // rank_type: string
}

export const getArgs = (str) => {
    let args = [] as ItemArg[];
    if (!str) return args;
    const found = str.match(/\{.*?\}/g);
    if (found) {
        found.forEach((x) => {
            args.push({name: x, target_id: ""});
        });
    }
    return args;
};

export class ScenarioContext {
}

export const Enecolor4Color: string[] = ["R", "Y", "G", "B"];
export const Enecolor16Color: string[] = [
    "ROS",
    "RCS",
    "RCG",
    "ROG",
    "YOS",
    "YCS",
    "YCG",
    "YOG",
    "GOS",
    "GCS",
    "GCG",
    "GOG",
    "BOS",
    "BCS",
    "BCG",
    "BOG",
];

// export function GenUnits(store) {
//     const storages = store.get("storages") as DataStorage[];
//     const checkedStorage = store.get("checkedStorage");
//     const units = [] as { unit: DataUnit; storage: DataStorage }[];
//     if (!storages || !checkedStorage) return [];
//     for (let key in checkedStorage) {
//         const o = storages.find((x) => x.id === key);
//         if (o) {
//             o.data.forEach((unit) => {
//                 units.push({unit: unit, storage: o});
//             });
//         }
//     }
//     return units;
// }

function genRandomName(len = 16) {
    let l = len;
    let c = "abcdefghijklmnopqrstuvwxyz0123456789";
    let cl = c.length;
    let r = "";
    for (let i = 0; i < l; i++) {
        r += c[Math.floor(Math.random() * cl)];
    }
    return r;
}

export interface LangDBData {
    idx: number;
    category: string;
    comment: string;
    order: string;
    japanese: string;
    english: string;
}

export interface EnecolorGroupData {
    category: string;
    comment: string;
    messages: string[];
}

const defaultUseMentoroid = {ena: true, rabic: false};

export type Image = {
    file?: File,
    url: string,
}

export const defPageSize = 5;
export const pageSizeKey = 'textInputPageSize';
export const initialImage: Image = {file: undefined, url: ""}

export default function ScenarioPage() {
    const [langData, setLangData] = useState([] as LangDBData[]);
    const [enecolorGroupData, setEnecolorGroupData] = useState(
        [] as EnecolorGroupData[]
    );
    const [scenario, setScenario] = useState<any>([]);
    const [title, setTitle] = useState("");
    const [cover, setCover] = useState("");
    const [explain, setExplain] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isDevActive, setIsDevActive] = useState(false);
    const [isTool, setIsTool] = useState(false);
    const [isOnlyImage, setIsOnlyImage] = useState(false);
    const [chapterIndex, setChapterIndex] = useState(0);
    const [chapterTitle, setChapterTitle] = useState("");
    const [chapterSubTitle, setChapterSubTitle] = useState("");
    const [chapterLink, setChapterLink] = useState<any>("");
    const [image, setImage] = useState<Image>(initialImage)
    const [imageYoutube, setImageYoutube] = useState<Image>(initialImage)
    const [newScenarioId, setNewScenarioId] = useState<string>(`sce_${uuidv4()}`);
    const [scenarioId, setScenarioId] = useState("");
    const [scenarioList, setScenarioList] = useAtom(scenarioListAtom);
    const [tabIndex, setTabIndex] = useState(0);
    const [uploadImages, setUploadImages] = useState<any>([]);
    const [images, setImages] = useState<any>([]);
    const [lang, setLang] = useState("japanese");
    const [deployMessage, setDeployMessage] = useState("");
    const [autoSaveMessage, setAutoSaveMessage] = useState("");
    const [usedMentoroid, setUsedMentoroid] = useState(defaultUseMentoroid);
    const [lastScenario, setLastScenario] = useAtom(lastScenarioAtom);
    const [pageSize, setPageSize] = useState<number>(defPageSize);
    const {scenarios} = useScenarios()
    const {isDeleteModal, toggleDeleteModal} = useModal()
    const [delIdImg, setDelIdImg] = useState<string>("");
    const [contentsDeleteId, setContentsDeleteId] = useState<any[]>([]);
    const {checkedDefinitionsId, setCheckDefinitionsId} = useCheckedDefinitions(scenarioId)
    const [deeplinkScenario, setDeeplinkScenario] = useState<string>("")
    const contentIndex = useMemo(() => {
        if (scenario.length === 0) return 0
        let maxContentIndex = scenario[0].index
        for (const content of scenario) {
            if (maxContentIndex < content.index)
                maxContentIndex = content.index
        }
        return maxContentIndex + 1
    }, [scenario])

    useEffect(() => {
        const _pageSize = Number(localStorage.getItem(pageSizeKey) ?? defPageSize)
        setPageSize(_pageSize)
    }, [])

    function loadLangDB() {
        axios.get(firebaseFunc("load_lang_db")).then((res) => {
            //axios.get("http://localhost:5000/").then(res => {
            let data = res?.data?.data?.langDb as LangDBData[];
            const groups = enecolorGroupData;
            data = data?.sort((a, b) => {
                const ao = parseInt(a.order);
                const bo = parseInt(b.order);
                if (ao > bo) return 1;
                if (ao < bo) return -1;
                return 0;
            });
            data?.forEach((x) => {
                if (x.comment != null && x.comment != "") {
                    const com = x.comment;
                    const cat = x.category;
                    const gidx = groups.findIndex(
                        (g) => g.category == cat && g.comment == com
                    );
                    if (gidx >= 0) {
                        groups[gidx].messages.push(x.japanese);
                    } else {
                        groups.push({
                            category: cat,
                            comment: com,
                            messages: [x.japanese],
                        });
                    }
                }
            });
            setEnecolorGroupData(groups);
            setLangData(data);
        });
    }

    function loadScenarios(clbFn?: any) {
        const _data = scenarios?.map((s) => {
            return {
                id: s?.id ?? "",
                contents: s?.contents ?? null,
                title: s?.title ?? "err",
                cover: s?.cover ?? "",
                deeplink: s?.deeplink ?? "",
                chapterIndex: s?.chapterIndex ?? "0",
                chapterTitle: s?.chapterTitle ?? "",
                chapterSubTitle: s?.chapterSubTitle ?? "",
                chapterLink: s?.chapterLink ?? "",
                chapterThumb: s?.chapterThumb ?? "",
                chapterThumbYoutube: s?.chapterThumbYoutube ?? "",
                isActive: s?.isActive ?? false,
                isTool: s?.isTool ?? false,
                isDevActive: s?.isDevActive ?? false,
                isOnlyImage: s?.isOnlyImage ?? false,
                explain: s?.explain ?? "",
                usedMentoroid: s?.usedMentoroid ?? {ena: true, rabic: false},
                checkedDefinitionsId: s?.checkedDefinitionsId ?? {}
            };
        }).sort((a, b) => {
            if (a.title != b.title) return a.title < b.title ? -1 : 1;
            return a.chapterIndex < b.chapterIndex ? -1 : 1;
        })
        setScenarioList(_data ?? [])
        if (isFunction(clbFn))
            clbFn(_data);
    }

    function loadScenarioFile(id: string, _listScenario?: any) {
        if (id === newScenarioId) {
            setScenarioId(id);
            setScenario([]);
            setTitle("");
            setCover("");
            setChapterIndex(0);
            setChapterTitle("");
            setChapterSubTitle("");
            setChapterLink("");
            setImage(initialImage);
            setIsActive(false);
            setIsDevActive(false);
            setIsTool(false);
            setIsOnlyImage(false);
            setExplain("");
            setUsedMentoroid(defaultUseMentoroid);
            setPageSize(defPageSize)
            setCheckDefinitionsId([])
            setDeeplinkScenario("")
        } else {
            const x = find(_listScenario, item => item.id === id)
            setScenario(x?.contents ?? []);
            setScenarioId(id);
            setTitle(x?.title ?? "");
            setCover(x?.cover ?? "");
            setChapterIndex(x?.chapterIndex ?? "");
            setChapterTitle(x?.chapterTitle ?? "");
            setChapterSubTitle(
                x?.chapterSubTitle ?? ""
            );
            setChapterLink(x?.chapterLink ?? "")
            setImage({...initialImage, url: x?.chapterThumb ?? ""})
            setImageYoutube({
                ...initialImage,
                url: x?.chapterThumbYoutube ?? ""
            })
            setIsActive(x?.isActive ?? false);
            setIsDevActive(x?.isDevActive ?? false);
            setIsTool(x?.isTool ?? false);
            setIsOnlyImage(x?.isOnlyImage ?? false);
            setExplain(x?.explain ?? "");
            setUsedMentoroid(
                x?.usedMentoroid ?? defaultUseMentoroid
            );
            setCheckDefinitionsId(x?.checkedDefinitionsId ?? []);
            if (x?.deeplink) {
                setDeeplinkScenario(x?.deeplink)
            } else {
                setDeeplinkScenario("")
            }
        }
        // autoSaveFlag = true; // enable auto save feature
        autoSaveFlag = false
        setAutoSaveMessage("自動保存有効");
    }

    function loadImages() {
        imageStorage
            .getAllFile(IMAGES)
            .then((res) => {
                const imgPromise = res.items.map(async (x) => {
                    const url = await imageStorage.getFileUrl(x.name, IMAGES);
                    return {name: x.name, url: url};
                });
                Promise.all(imgPromise).then((x) => {
                    setImages([{name: "", url: ""}].concat(x) as any);
                });
            })
            .catch((error) => console.error(error));
    }

    async function loadAndDelThumbs(commonThumb) {
        const res = await imageStorage.getAllFile(commonThumb + "/" + scenarioId)
        if (res.items.length === 0) return
        const nameList = res.items.map(x => x.name);
        const deletePromises = nameList.map(x => imageStorage.delFile(x, commonThumb + "/" + scenarioId))
        await Promise.all(deletePromises);
    }

    const callbackRef = useRef<() => Promise<void>>(autoSave);

    useEffect(() => {
        callbackRef.current = autoSave; // 新しいcallbackをrefに格納！
    }, [autoSave]);

    useEffect(() => {
        const tick = async () => {
            await callbackRef.current();
        };
        const id = setInterval(async () => {
            await tick()
        }, 180000);
        return () => {
            clearInterval(id);
        };
    }, []); //refはミュータブルなので依存配列に含めなくてもよい

    useEffect(() => {
        loadLangDB();
        loadImages();

        const clb = (_listScenario) => {
            const isExist = _listScenario.some(s => s.id === lastScenario)
            setScenarioId(lastScenario)
            loadScenarioFile(isExist ? lastScenario : newScenarioId, _listScenario);
        };

        loadScenarios(clb);
    }, [lastScenario, scenarios, newScenarioId]);

    async function autoSave() {
        if (!autoSaveFlag) {
            setAutoSaveMessage("自動保存停止中");
            return;
        }
        const date1 = new Date();
        await saveScript(true);
        setAutoSaveMessage("自動保存中（前回:" + date1.toLocaleString() + ")");
    }

    async function saveScript(isAutoSave: boolean) {
        try {
            let url: string = image.url;
            let urlYoutube: string = imageYoutube.url;
            if (image.file && !isAutoSave) {
                await loadAndDelThumbs(THUMB);
                const imageSnapshot = await imageStorage.putFile(uniqid("image_"), image.file, THUMB + "/" + scenarioId);
                url = await imageSnapshot.ref.getDownloadURL();
            }
            if (imageYoutube.file && !isAutoSave) {
                await loadAndDelThumbs(THUMBYTUBE);
                const imageSnapshot = await imageStorage.putFile(uniqid("image_"), imageYoutube.file, THUMBYTUBE + "/" + scenarioId);
                urlYoutube = await imageSnapshot.ref.getDownloadURL();
            }

            let obj = {
                id: scenarioId,
                title: title,
                cover: cover,
                contents: scenario,
                chapterIndex: chapterIndex,
                chapterTitle: chapterTitle,
                chapterSubTitle: chapterSubTitle,
                chapterLink: chapterLink,
                chapterThumb: url,
                chapterThumbYoutube: urlYoutube,
                deeplink: deeplinkScenario,
                isActive: isActive,
                isTool: isTool,
                isOnlyImage: isOnlyImage,
                isDevActive: isDevActive,
                explain: explain,
                checkedDefinitionsId: checkedDefinitionsId,
                usedMentoroid: usedMentoroid,
            };

            const currentScenario = scenarioList.find(s => s.id === scenarioId)
            setDeployMessage("送信中");
            if (scenarioId !== newScenarioId) {
                await axios.post('/scenario/update', {
                    scenariosUpdates: {...obj},
                    contentsDeleteId: currentScenario?.contents?.length > 0 ? contentsDeleteId : [],
                });
            } else
                await axios.post('/scenario/create', obj)
            setDeployMessage("完了");
        } catch (error) {
            setDeployMessage("エラー");
            console.error(error);
        } finally {
            setNewScenarioId(`sce_${uuidv4()}`)
            setLastScenario(scenarioId)
            setContentsDeleteId([])
        }
    }

    function addText(langItem: any) {
        setScenario([...scenario, new TextItem(contentIndex, "text", langItem)] as any);
    }

    function addDefaultOne() {
        setScenario([...scenario, new TextItem(contentIndex, "text", {})] as any);
    }

    function addAllText(events: any) {
        let data: any = events.map((item, index) => new TextItem(contentIndex + index, "text", (item)))
        setScenario([...scenario, ...data] as any);
    }

    function addImage(name: string, url: string) {
        setScenario([...scenario, new ImageItem(contentIndex, name, url)] as any);
    }

    function addInput() {
        setScenario([...scenario, new InputItem(contentIndex, "string")] as any);
    }

    function addInputSlider() {
        setScenario([...scenario, new InputItem(contentIndex, "number")] as any);
    }

    function addChoice() {
        setScenario([...scenario, new ChoiceItem(contentIndex)] as any);
    }

    function addIf() {
        setScenario([...scenario, new IfStartItem(contentIndex), new IfEndItem(contentIndex + 1)] as any);
    }

    function addMotion() {
        setScenario([...scenario, new MotionItem(contentIndex)] as any);
    }

    function addSlide() {
        setScenario([...scenario, new SlideItem(contentIndex)] as any);
    }

    function addFor() {
        setScenario([...scenario, new ForStartItem(contentIndex), new ForEndItem(contentIndex + 1)] as any);
    }

    function addDelay() {
        setScenario([...scenario, new DelayItem(contentIndex)] as any);
    }

    function addRecord() {
        setScenario([...scenario, new RecordStartItem(contentIndex, scenarioId), new RecordEndItem(contentIndex + 1, scenarioId)])
    }

    function addGraph(id: string) {
        setScenario([...scenario, new GraphItem(contentIndex, id)] as any);
    }

    function addChatgpt() {
        setScenario([...scenario, new chatgptItem(contentIndex)] as any);
    }

    function addEnecolor(enecolorItem: EnecolorItem) {
        if (Object.keys(enecolorItem).length > 0) {
            enecolorItem.arg.target_id = scenarioId
            enecolorItem.index = contentIndex
            setScenario([...scenario, enecolorItem] as any);
            return
        }
        setScenario([...scenario, new EnecolorItem(contentIndex, scenarioId)] as any);
    }

    function addBlockExitImage() {
        setScenario([...scenario, new BlockExitImageItem(contentIndex)] as any);
    }

    function changeTabIndex(e: any, newValue: number) {
        setTabIndex(newValue);
    }

    function changeUploadImage(e: any) {
        const files = e.target.files;
        if (files.length > 0) {
            const fileArr = [];
            fileArr.push.apply(fileArr, files);
            const newUploadImages = fileArr.map((file) => ({
                raw: file,
                url: createObjectURL(file),
            }));
            setUploadImages([...uploadImages, ...newUploadImages]);
        }
    }

    function doUploadImage(e: any) {
        const uploadPromises: Promise<firebase.storage.UploadTaskSnapshot>[] = [];
        uploadImages.forEach((image, index) => {
            uploadPromises.push(
                imageStorage.putFile(uuidv4(), image.raw, IMAGES)
            );
        });
        Promise.all(uploadPromises)
            .then((x) => {
                console.log("uploaded");
                setUploadImages([]);
                loadImages();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function delScenarioItem(idx: number, data?: any) {
        setScenario(scenario.filter((x, i) => x.id !== idx));
        const _contentsDeleteId = [...contentsDeleteId, idx]
        setContentsDeleteId(_contentsDeleteId)
    }

    const activeSymbol = (s) => {
        return s.isActive ? "○" : s.isDevActive ? "△" : "×";
    };
    const itemTitle = (s) => {
        return `「${s.title}」_${s.chapterIndex}_${s.chapterTitle}`;
    };

    const handleSetPageSize = (pageSize) => {
        setPageSize(pageSize)
        localStorage.setItem(pageSizeKey, pageSize);
    }

    const onScrollPassBtn = (element, elementTop) => {
        if (window.scrollY > elementTop) {
            element.classList.add(`fixed`, `left-0`, `w-full`, `z-50`);
        } else {
            element.classList.remove(`fixed`, `left-0`, `w-full`, `z-50`);
        }
    }
    const eleRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!eleRef.current) return
        const element = eleRef.current
        const elementTop = eleRef.current.offsetTop;

        window.addEventListener('scroll', () => onScrollPassBtn(element, elementTop));

        return () => {
            document.removeEventListener("scroll", () => onScrollPassBtn(element, elementTop));
        };
    }, [])

    const handleYes = () => {
        if (!delIdImg) return
        imageStorage.delFile(delIdImg, IMAGES).then(() => {
            toggleDeleteModal(false);
            toast.success(`削除しました`);
            loadImages()
        })
    }

    return (
        <div className={"w-full m-2"}>
            <div className={"flex flex-col gap-1"}>
                <ScenarioSelector
                    value={scenarioId}
                    onChange={(e) => {
                        loadScenarioFile(e.target.value as any);
                        setLastScenario(e.target.value as any);
                    }}
                    newScenarioId={newScenarioId}
                    scenarioList={scenarioList}
                    callbackfn={(s: any) => {
                        return (
                            <MenuItem key={s.id} value={s.id}>
                                {activeSymbol(s)} {itemTitle(s)}
                            </MenuItem>
                        );
                    }}
                />
                <LanguageSelector
                    value={lang}
                    onChange={(e) => setLang(e.target.value as any)}
                />
                <div ref={eleRef}>
                    <DeployButton
                        onClick={() => saveScript(false)}
                        scenarioId={scenarioId}
                        newScenarioId={newScenarioId}
                        deployMessage={deployMessage}
                    />
                </div>
                <AutoSaveCheckbox
                    onChange={(e) => {
                        const f = e.target.checked;
                        autoSaveFlag = f;
                        setAutoSaveMessage(f ? "自動保存動作中" : "自動保存停止中");
                    }}
                    autoSaveMessage={autoSaveMessage}
                    disabled={true} // enable auto save feature
                />
                <MetadataView
                    scenarioId={scenarioId}
                    newScenarioId={newScenarioId}
                    deeplinkScenario={deeplinkScenario}
                    setDeeplinkScenario={setDeeplinkScenario}
                    value={cover}
                    loadAndDelThumbs={loadAndDelThumbs}
                    setImage={setImage}
                    setImageYoutube={setImageYoutube}
                    onChange={(e) => setCover(e.target.value)}
                    value1={title}
                    onChange1={(e) => setTitle(e.target.value)}
                    value2={explain}
                    onChange2={(e) => setExplain(e.target.value)}
                    checked={isActive}
                    onChange3={(e) => setIsActive(e.target.checked)}
                    checked1={isDevActive}
                    onChange4={(e) => setIsDevActive(e.target.checked)}
                    checked2={isTool}
                    onChange5={(e) => setIsTool(e.target.checked)}
                    checked3={isOnlyImage}
                    onChange6={(e) => setIsOnlyImage(e.target.checked)}
                    value3={chapterIndex}
                    onChange7={(e) => setChapterIndex(parseInt(e.target.value))}
                    value4={chapterTitle}
                    onChange8={(e) => setChapterTitle(e.target.value)}
                    value5={chapterSubTitle}
                    onChange9={(e) => setChapterSubTitle(e.target.value)}
                    value6={chapterLink}
                    onChange10={(e) => setChapterLink(e.target.value)}
                    value7={image}
                    onChange11={setImage}
                    value8={imageYoutube}
                    onChange12={setImageYoutube}
                />
                <MentaroidView
                    usedMentoroid={usedMentoroid}
                    onChange={(e) =>
                        setUsedMentoroid({
                            ena: e.target.checked,
                            rabic: usedMentoroid.rabic,
                        })
                    }
                    onChange1={(e) =>
                        setUsedMentoroid({
                            ena: usedMentoroid.ena,
                            rabic: e.target.checked,
                        })
                    }
                />
                <DataStructureView sid={scenarioId}/>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <ScenarioContentBuilder
                        lang={lang}
                        langData={langData}
                        scenario={scenario}
                        setScenario={setScenario}
                        enecolorGroupData={enecolorGroupData}
                        onDeleteItem={(idx: number, data: any) =>
                            delScenarioItem(idx, data)
                        }
                        updateScenario={(s: any) => setScenario(s)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <BuilderSelector value={tabIndex} onChange={changeTabIndex}/>
                        <div>
                            {tabIndex === 0 ? (
                                <TextBuilderList
                                    onClick={() => loadLangDB()}
                                    field={lang}
                                    data={langData}
                                    onClick1={(event, rowData) => {
                                        addText(rowData);
                                    }}
                                    addDefaultOne={addDefaultOne}
                                    onClickAll={(events) => {
                                        addAllText(events);
                                    }}
                                    pageSize={pageSize}
                                    handleSetPageSize={handleSetPageSize}
                                />
                            ) : tabIndex === 1 ? (
                                <div className={`flex flex-col`}>
                                    <ImageBuilderUploader
                                        onChange={changeUploadImage}
                                        uploadImages={uploadImages}
                                        isInputExist={false}
                                        onClick={doUploadImage}
                                        addBlockExitImage={addBlockExitImage}
                                    />
                                    <ImageBuilderImageList
                                        images={images}
                                        element={(image: any, idx: number) => {
                                            return (
                                                <ImageBuilderImageItem
                                                    key={image.id}
                                                    image={image}
                                                    onClick={(e) => addImage(image.name, image.url)}
                                                    onShowModal={() => {
                                                        setDelIdImg(image.name);
                                                        toggleDeleteModal(true);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            ) : tabIndex === 2 ? (
                                <EnecolorBuilder
                                    onClick1={(enecolorItem: EnecolorItem) => addEnecolor(enecolorItem)}
                                />
                            ) : tabIndex === 3 ? (
                                <GraphBuilder
                                    onClick={() => addGraph(scenarioId)}
                                />
                            ) : tabIndex === 4 ? (
                                <InputBuilder
                                    onClick={(e) => addInput()}
                                    onClick1={(e) => addInputSlider()}
                                    onClick2={(e) => addChoice()}
                                />
                            ) : tabIndex === 5 ? (
                                <ControlBuilder
                                    onAddIf={addIf}
                                    onAddFor={addFor}
                                    onAddDelay={addDelay}
                                    onAddRecord={addRecord}
                                />
                            ) : tabIndex === 6 ? (
                                <MotionBuilder onClick={(e) => addMotion()}/>
                            ) : tabIndex === 7 ? (
                                <SlideBuilder onClick={(e) => addSlide()}/>
                            ) : tabIndex === 8 ? (
                                <ChatgptComponent onClick={(e) => addChatgpt()}/>
                            ) : null}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <DeleteModal
                title={`削除しますか?`}
                show={isDeleteModal}
                setShow={toggleDeleteModal}
                onYes={handleYes}
                onNo={() => {
                    toggleDeleteModal(false);
                }}
            ></DeleteModal>
        </div>
    );
}
