import {atomWithStorage} from 'jotai/utils';
import {atom} from 'jotai';
import {scenarioListAtom} from './scenarioList.atom';
import find from 'lodash/find';

const lastScenarioAtom = atomWithStorage('lastScenario', '');

const lastScenarioDataAtom = atom((get) => {
    const scs = get(scenarioListAtom);
    return find(scs, {id: get(lastScenarioAtom)});
});

export {
    lastScenarioAtom,
    lastScenarioDataAtom,
};
