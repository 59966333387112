import React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import useCheckedDefinitions from "../../hooks/useCheckedDefinitions";
import {useDefinitions} from "../../hooks/definitions";
import {isArray} from "lodash"

type Props = {
    sid: string
}
export default function DefinitionSelectComponent({sid}: Props) {
    const {checkedDefinitionsId, setCheckDefinitionsId} = useCheckedDefinitions(sid)
    const {definitions} = useDefinitions()

    const handleChange = (id: string) => {
        if (checkedDefinitionsId?.includes(id)) {
            const _checkedDefinitionsId = checkedDefinitionsId.filter(cid => cid !== id)
            setCheckDefinitionsId(_checkedDefinitionsId);
            return;
        }
        setCheckDefinitionsId([...checkedDefinitionsId, id])
    }
    return <div>
        {
            definitions.map((d, si) => {
                return <FormControlLabel
                    key={si}
                    control={
                        <Checkbox
                            checked={isArray(checkedDefinitionsId) ? checkedDefinitionsId?.includes(d.id) : false}
                            onChange={(e) => {
                                handleChange(d.id)
                            }}
                        />
                    }
                    label={d.name}
                />
            })
        }
    </div>
}
