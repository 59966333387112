import {FormControl, FormHelperText, MenuItem, Select, TextField} from "@material-ui/core";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Image, initialImage} from "./ScenarioPage";
import {EnecolorItem} from "./Items";
import {AddButton} from "../../component/AddButton";
import {imageStorage} from "../../firebase/store";
import {ENECOLORIMG} from "../../constants/folder";
import firebase from "firebase";
import {DeleteButton} from "../../component/DeleteButton";
import {v4 as uuidv4} from 'uuid';
import {useAtom} from "jotai";
import {eneColorNameAtom} from "../../atoms/eneColorName.atom";

export const Enecolor4Image: any[] = [
    {name: "Y", url: ""},
    {name: "G", url: ""},
    {name: "R", url: ""},
    {name: "B", url: ""}
]

export const Enecolor16Image: any[] = [
    {name: "YCS", url: ""},
    {name: "YCG", url: ""},
    {name: "GCS", url: ""},
    {name: "GCG", url: ""},
    {name: "YOS", url: ""},
    {name: "YOG", url: ""},
    {name: "GOS", url: ""},
    {name: "GOG", url: ""},
    {name: "RCS", url: ""},
    {name: "RCG", url: ""},
    {name: "BCS", url: ""},
    {name: "BCG", url: ""},
    {name: "ROS", url: ""},
    {name: "ROG", url: ""},
    {name: "BOS", url: ""},
    {name: "BOG", url: ""},
]

type Props = {
    item: EnecolorItem
    onUpdate: any
}
const TOTALENECOLOR4 = 4;
const TOTALENECOLOR16 = 16;

export default function EnecolorComponent({item, onUpdate}: Props) {
    const inputRef = useRef<Array<HTMLDivElement | null>>([])
    const [images, setImages] = useState<Image[]>([])
    if (item.mentroid_name == null) item.mentroid_name = "エナ"
    const [eneColorName, setEneColorName] = useAtom(eneColorNameAtom);
    const isEnecolor4 = item.output_type === "enecolor_4";
    const isEnecolor16 = item.output_type === "enecolor_16"
    const colors = isEnecolor4 ? Enecolor4Image : Enecolor16Image;
    if (isEnecolor4 && item.arg?.groupsImg?.length !== 4 && item.arg?.groupsImg) item.arg.groupsImg = [...colors]
    if (isEnecolor16 && item.arg?.groupsImg?.length !== 16 && item.arg?.groupsImg) item.arg.groupsImg = [...colors]

    function getInitialImgs(max) {
        const initialImgs: Image[] = [];
        for (let i = 0; i < max; i++) {
            initialImgs.push(initialImage);
        }
        return initialImgs
    }

    useEffect(() => {
        inputRef.current = inputRef?.current?.slice(0, item.output_type === "enecolor_4" ? TOTALENECOLOR4 : TOTALENECOLOR16);
        const initialImgs = getInitialImgs(inputRef?.current?.length);
        setImages(initialImgs);
    }, [item.output_type]);

    useEffect(() => {
        function loadImages() {
            const updatedTotalEnecolor = item.output_type === "enecolor_4" ? TOTALENECOLOR4 : TOTALENECOLOR16
            const initialImgs = getInitialImgs(updatedTotalEnecolor)

            item?.arg?.groupsImg && item.arg.groupsImg.forEach((gr, gri) => {
                if (gr.url.length > 0) {
                    initialImgs[gri] = {...initialImage, url: gr.url}
                }
            })
            setImages(initialImgs)
        }

        loadImages()
    }, [item.arg, item.output_type])

    const saveEnecolorSetting = async () => {
        if (eneColorName) {
            const _item = JSON.parse(JSON.stringify(item)); //clone deep
            _item.id = ""
            _item.arg.target_id = ""

            localStorage.setItem(eneColorName, JSON.stringify(_item))
            const eneColors = JSON.parse(localStorage.getItem("enecolors") ?? '[]')
            eneColors.push(eneColorName)
            localStorage.setItem("enecolors", JSON.stringify(eneColors))
            setEneColorName("")
        }
    }
    const handleUpload = async (e: ChangeEvent<HTMLInputElement>, grIndex: any) => {
        if (e.target.files && e.target.files[0]) {
            const fileImage = e.target.files[0];
            // const previewImageUrl = URL.createObjectURL(fileImage);
            const snap: firebase.storage.UploadTaskSnapshot = await imageStorage.putFile(uuidv4(), fileImage, ENECOLORIMG)
            const url = await snap.ref.getDownloadURL();
            item.arg.groupsImg && (item.arg.groupsImg[grIndex].url = url)
            onUpdate({...item})

            const _images = JSON.parse(JSON.stringify([...images]))
            _images[grIndex] = {file: fileImage, url: url}
            setImages([..._images])
        }
    }

    const handleDeleteImg = (grIndex: number) => {
        item.arg.groupsImg && (item.arg.groupsImg[grIndex].url = "")
        onUpdate({...item})

        images[grIndex] = {...initialImage}
        setImages([...images])
    }

    return <div>
        <div key="type_select">
            <FormControl variant="outlined" margin='dense'>
                <FormHelperText>種別</FormHelperText>
                <Select
                    value={item.output_type}
                    onChange={(e) => {
                        const t = e.target.value as string
                        item.output_type = t
                        item.arg && (item.arg.rank = null) // clear rank when output_type change
                        onUpdate({...item})
                    }}
                >
                    <MenuItem value={"enecolor_4"}>エネカラー４</MenuItem>
                    <MenuItem value={"enecolor_16"}>エネカラー１６</MenuItem>
                </Select>
            </FormControl>
        </div>
        <div>
            {item.output_type === "enecolor_4" || item.output_type === "enecolor_16" ?
                <div>
                    <div>
                        <hr/>
                        <div className={`mb-3`}>Rank setting</div>
                        {/*<div className={`mb-3`} key="type_select">*/}
                        {/*    <FormControl variant="outlined" margin='dense'>*/}
                        {/*        <FormHelperText>Type</FormHelperText>*/}
                        {/*        <Select*/}
                        {/*            value={arg.rank_type}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                const t = e.target.value as string*/}
                        {/*                arg.rank_type = t*/}
                        {/*                onUpdate({...item})*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <MenuItem value={"vertical"}>Vertical</MenuItem>*/}
                        {/*            <MenuItem value={"horizontal"}>Horizontal</MenuItem>*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*</div>*/}
                        <div
                            className={`mb-6`}>
                            <FormControl variant="outlined">
                                <FormHelperText>Rank</FormHelperText>
                                <Select
                                    value={item.arg?.rank}
                                    onChange={(e) => {
                                        item.arg && (item.arg.rank = (e.target.value || null) as number | null)
                                        onUpdate({...item})
                                    }}
                                >
                                    <MenuItem key={0} value={""}>None</MenuItem>
                                    {
                                        colors?.map((x, xi) => {
                                            return <MenuItem key={xi + 1}
                                                             value={xi + 1}>{xi + 1}位</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <hr/>
                        <div className={`mb-3`}>Image setting</div>
                        <div
                            className={`grid grid-rows-2 grid-flow-col gap-4 w-fit ${item.output_type === "enecolor_16" && `grid-rows-4`}`}>
                            {
                                item.arg?.groupsImg?.map((c, ci) => {
                                    return <div key={"color_" + ci} className={`flex flex-col items-center`}>
                                        <div>{c?.name}</div>
                                        <input
                                            name={`eneImage_${c?.name}`}
                                            ref={el => inputRef.current[ci] = el}
                                            type="file" accept=".jpg,.jpeg,.png" className={`hidden`}
                                            onChange={(e) => handleUpload(e, ci)}
                                        />
                                        <div
                                            className={`w-20 h-20 flex flex-col justify-center items-center relative border-dashed border-2 border-light-gray cursor-pointer`}
                                            onClick={() => inputRef?.current[ci]?.click()}
                                        >
                                            {images[ci]?.url && images[ci]?.url !== "" ?
                                                <img src={images[ci]?.url}
                                                     className={'w-full h-full object-contain cursor-pointer'}
                                                     alt="chapter_thumb"/> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26"
                                                     height="26" fill="currentColor"
                                                     className={"bi bi-upload select-none outline-none"}
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                    <path
                                                        d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                                </svg>
                                            }
                                        </div>
                                        <DeleteButton onClick={() => handleDeleteImg(ci)}/>
                                    </div>
                                    // return <div key={"color_" + ci}>
                                    //     <FormControl variant="outlined" style={{width: "100%"}}>
                                    //         <TextField
                                    //
                                    //             label={colors[ci]}
                                    //             value={arg.groupsImg && arg.groupsImg.length >= ci ? arg.groupsImg[ci] : ""}
                                    //             onChange={(e) => {
                                    //                 let s = e.target.value
                                    //                 let group = item.args[argi].groupsImg
                                    //                 if (group && group.length >= ci) {
                                    //                     group[ci] = s
                                    //                 }
                                    //                 this.props.onUpdate(item)
                                    //             }
                                    //             }
                                    //             fullWidth={true}
                                    //             multiline={true}
                                    //             margin="none"
                                    //             variant="outlined"
                                    //             size="small"
                                    //         />
                                    //     </FormControl>
                                    // </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                :
                null
            }
        </div>
        <hr/>
        <div className={`flex flex-col w-fit gap-3 mt-3`}>
            <TextField
                label="Enecolor name"
                size="small"
                margin="none"
                variant={"filled"}
                value={eneColorName}
                onChange={e => setEneColorName(e.target.value)}
                helperText={"Specify name to save this setting"}
            />
            <AddButton text={"save"} onClick={saveEnecolorSetting}></AddButton>
        </div>
    </div>
}
