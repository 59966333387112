import {FormControl, FormHelperText, MenuItem, Select, TextField} from "@material-ui/core";
import React from "react";
import {getArgs} from "./ScenarioPage";
import {IfStartItem} from "./Items";
import {useGenUnits} from "../../hooks/useGenUnits";

type Props = {
    item: IfStartItem
    onUpdate: (arg: IfStartItem) => void
}
export default function IfStartComponent({item, onUpdate}: Props) {
    const units = useGenUnits()

    return <div>
        <TextField

            size="small"
            id="outlined-name"
            label="条件式"
            value={item.condition}
            onChange={(e) => {
                item.condition = e.target.value as string
                const newArgs = getArgs(item.condition);
                newArgs.forEach(a => {
                    const p = item.args.find(b => a.name == b.name);
                    if (p) {
                        a.target_id = p.target_id;
                    }
                })
                item.args = newArgs;
                onUpdate(item);
            }}
            margin="none"
            variant="outlined"
        />
        <div>
            <div> --- 引数 ---</div>
            {
                item.args ? item.args.map((arg, argi) => {
                        return <div key={argi}>
                            <FormControl variant="outlined" margin='dense'>
                                <FormHelperText>{arg.name}</FormHelperText>
                                <Select
                                    value={arg.target_id}
                                    onChange={(e) => {
                                        item.args[argi].target_id = e.target.value as string
                                        onUpdate(item)
                                    }}
                                >
                                    <MenuItem value={""}>未選択</MenuItem>
                                    {
                                        units.map((unit, ui) => {
                                            return <MenuItem key={ui}
                                                             value={unit.unit.id}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    })
                    : null
            }
        </div>
    </div>
}
