import {EneColorItemArg, getArgs, ItemArg} from "./ScenarioPage";
import {genId} from "cf-gen-id";

export class Item {
    id: string = genId({prefix: "cont_", size: 10})
    index: number = 0
    type: string = ""
}

export class TextItem extends Item {
    message_id: number = 0
    message: { japanese: string, english: string } = {japanese: "", english: ""}
    args: ItemArg[] = []
    output_type: string = ""
    mentroid_name: string = "エナ"

    constructor(_index: number, _type: string, langItem: any) {
        super()
        this.index = _index
        this.type = _type;
        this.message_id = langItem.id
        this.message.japanese = langItem.japanese
        this.message.english = langItem.english
        this.args = getArgs(langItem.japanese)
        this.output_type = "simple"
        this.mentroid_name = "エナ"
    }
}

export class ImageItem extends Item {
    url: string = ""
    filename: string = ""

    constructor(_index: number, name: string, url: string) {
        super()
        this.index = _index
        this.type = "image"
        this.filename = name
        this.url = url
    }
}

export class InputItem extends Item { //TODO
    target_id: string = ""
    input_data: string = ""
    input_type: "string" | "number" = "string"
    is_enecolor_bar: boolean = false
    minValue: number = 0.0
    maxValue: number = 1.0

    constructor(_index: number, input_type: "string" | "number") {
        super()
        this.index = _index
        this.type = "input"
        this.is_enecolor_bar = false;
        this.input_type = input_type
    }
}

export class SlideItem extends Item {
    target_id: string = ""
    input_type: string = "slide"
    page_no: number = 0
    recent_object_id: string = ""
    json: any = {}
    error_message: string = ""

    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "slide"
        this.input_type = "slide"
    }
}

export class ChoiceItem extends Item {
    target_id: string = ""
    input_data: string = ""
    input_type: "string" | "number" = "string"
    choices_targets: { target_id: string, input_data: string, text: string }[] = []

    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "choice"
    }
}

export class IfStartItem extends Item {
    condition: string = ""
    args: ItemArg[] = []

    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "if_start"
        this.args = getArgs(this.condition);
    }
}

export class IfEndItem extends Item {
    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "if_end"
    }
}

export class ForStartItem extends Item {
    condition: string = ""
    counter: ItemArg = {name: "i", target_id: ""};
    args: ItemArg[] = []

    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "for_start"
        this.args = getArgs(this.condition);
    }
}

export class ForEndItem extends Item {
    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "for_end"
    }
}

export class MotionItem extends Item {
    mentroid_name: string = "エナ"
    motion_type: string = "そのまま"
    location: string = "そのまま"
    flip: string = "そのまま"
    show: string = "そのまま"

    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "motion"
    }
}

export class DelayItem extends Item {
    seconds: number = 10

    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "delay"
    }
}

export class GraphItem extends Item {
    target_id: string = ""

    constructor(_index: number, id: string) {
        super()
        this.index = _index
        this.type = "graph"
        this.target_id = id
    }
}

export class chatgptItem extends Item {
    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "chatGPT"
    }
}

export class BlockExitImageItem extends Item {
    constructor(_index: number) {
        super()
        this.index = _index
        this.type = "blockExitImage"
    }
}


export class EnecolorItem extends Item {
    arg: EneColorItemArg
    output_type: string = ""
    mentroid_name: string = "エナ"

    constructor(_index: number, target_id: string) {
        super()
        this.index = _index
        this.type = "enecolor_rank_img"
        this.arg = {
            target_id,
            groupsImg: [{name: "Y", url: ""},
                {name: "G", url: ""},
                {name: "R", url: ""},
                {name: "B", url: ""}],
            rank: null,
        }
        this.output_type = "enecolor_4"
        this.mentroid_name = "エナ"
    }
}

export class RecordStartItem extends Item {
    target_id: string = ""

    constructor(_index: number, target_id: string) {
        super()
        this.target_id = target_id
        this.index = _index
        this.type = "record_start"
    }
}

export class RecordEndItem extends Item {
    target_id: string = ""

    constructor(_index: number, target_id: string) {
        super()
        this.target_id = target_id
        this.index = _index
        this.type = "record_end"
    }
}
