import React, {RefObject, useMemo, useRef, useState} from "react";
import {AppBar, Button, FormControl, FormHelperText, MenuItem, Select, Tab, Tabs, TextField} from "@material-ui/core";
import MaterialTable from "material-table";
import {LangDBData} from "./ScenarioPage";
import {AddButton} from "../../component/AddButton";
import {NormalButton} from "../../component/NormalButton";
import {DeleteButton} from "../../component/DeleteButton";
import {EditButton} from "../../component/EditButton";
import ShowButton from "../../component/ShowButton";
import {EnecolorItem} from "./Items";
import {useAtomValue} from "jotai";
import {eneColorNameAtom} from "../../atoms/eneColorName.atom";

export function TextBuilderList(props: {
    onClick: () => void;
    field: string;
    data: LangDBData[];
    onClick1: (event, rowData) => void;
    onClickAll: (events) => void;
    addDefaultOne: (item) => void;
    pageSize: number,
    handleSetPageSize: (arg: number) => void,
}) {
    const tableRef: any = React.useRef();
    const [tableSearch, setTableSearch] = useState<any>([])
    let checkDisabled = Boolean(tableSearch?.length || props.data)

    return (
        <div>
            <NormalButton
                text={"再読み込み"}
                onClick={props.onClick}
                className={"m-2"}
            />
            <div className={'relative'}>
                <div className={'absolute top-12 left-1 h-8 flex flex-row items-center'}>
                    <button
                        disabled={!checkDisabled}
                        className={`z-50 bg-sky-200 h-full w-16 mr-2 ${checkDisabled ? 'hover:bg-sky-300 cursor-pointer' : ''} rounded border border-gray-300 p-1`}
                        onClick={() => {
                            let data = tableSearch?.length ? tableSearch : props.data
                            if (!data?.length) return
                            props.onClickAll(data)
                        }}
                    >一括登録
                    </button>
                    <button
                        className={`z-50 bg-sky-200 h-full w-16 hover:bg-sky-300 cursor-pointer rounded border border-gray-300 p-1`}
                        onClick={props.addDefaultOne}
                    >
                        <div className={'relative'}>
                            <div
                                className={'absolute top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 w-1 h-6 bg-gray-400 rounded'}/>
                            <div
                                className={'absolute top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 w-1 h-6 bg-gray-400 rounded rotate-90'}/>
                        </div>
                    </button>
                </div>
                <MaterialTable
                    tableRef={tableRef}
                    key={props.pageSize}
                    columns={[
                        {title: "カテゴリ", field: "category"},
                        {title: "メッセージ", field: props.field},
                    ]}
                    onSearchChange={() => {
                        if (!tableRef?.current?.dataManager)
                            return
                        setTableSearch(tableRef?.current?.dataManager.searchedData)
                    }}
                    onChangeRowsPerPage={props.handleSetPageSize}
                    data={props.data}
                    title="言語DB"
                    options={{
                        pageSize: props.pageSize,
                        pageSizeOptions: [5, 10, 20, 30, 60, 100],
                        toolbar: true,
                        paging: true
                    }}
                    actions={[
                        {
                            icon: "add",
                            tooltip: "シナリオに追加",
                            onClick: props.onClick1,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export function ImageBuilderUploader(props: {
    onChange: (e: any) => void;
    uploadImages: any;
    isUploading?: boolean;
    isInputExist: boolean;
    onClick: (e: any, ref?: RefObject<HTMLInputElement | null>) => void;
    addBlockExitImage?: () => void
}) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);
    return (
        <div>
            <div className={`flex justify-between`}>
                <input
                    type="file"
                    accept="image/png"
                    onChange={props.onChange}
                    multiple
                    ref={inputRef}
                />
                {props.addBlockExitImage && <NormalButton styles={'100px'} text={"AddBlockExit"}
                                                          onClick={props.addBlockExitImage}/>}
            </div>
            <div className={`flex gap-1 items-center`}>
                {props.uploadImages.map((image) => (
                    <div key={image.id} className={`flex gap-1 flex-col justify-center`}>
                        <img src={image.url} alt="banner" width="100px"/>
                        {props.isInputExist && (
                            <TextField
                                label="リンク"
                                size="small"
                                margin="none"
                                variant="outlined"
                                inputRef={textInputRef}
                                placeholder="https://www.example.com"
                            />
                        )}
                    </div>
                ))}
            </div>
            <NormalButton
                isUploading={props.isUploading || false}
                onClick={async (e) => {
                    await props.onClick(e, textInputRef);
                    if (inputRef.current !== null) {
                        inputRef.current.value = "";
                    }
                }}
                text={"アップロード"}
            />
        </div>

    );
}

export function ImageBuilderImageList(props: {
    images: any;
    element: (image: any, idx: number) => JSX.Element;
}) {
    return (
        <div className={"grid place-items-center grid-cols-3 gap-1"}>
            {props.images.map(props.element)}
        </div>
    );
}

export function ImageBuilderImageItem(props: {
    image: any;
    onClick: (e, ref: RefObject<HTMLInputElement | null>) => void;
    onApply?: (e, ref: RefObject<HTMLInputElement | null>) => void;
    onShowModal?: (e) => void;
    onShowClick?: (e) => void;
    isBannerPopup?: boolean;
}) {
    const textInputRef = useRef<HTMLInputElement | null>(null);
    const [isInput, setIsInput] = useState(false);
    return (
        <div className={"flex flex-col items-center justify-end"}>
            {props.image.url == "" ? (
                <div
                    style={{
                        width: "100px",
                        aspectRatio: "1/1",
                    }}
                >
                    画像なし
                </div>
            ) : (
                <a href={props.image.pageUrl} target="_blank">
                    <img
                        src={props.image.url}
                        width="100px"
                        style={{
                            margin: "5px",
                            border: "solid 1px",
                        }}
                        className={`${(!props.image.isShow && props.isBannerPopup) ? `opacity-20` : `opacity-100`} hover:opacity-100`}
                        alt={props.image.name}
                    />
                </a>
            )}

            <TextField
                label="ページ"
                size="small"
                margin="none"
                variant="outlined"
                inputRef={textInputRef}
                placeholder="https://www.example.com"
                style={{display: isInput ? "block" : "none"}}
            />

            {/*<Button variant="contained"*/}
            {/*        onClick={props.onClick}*/}
            {/*>＋</Button>*/}

            <DeleteButton
                onClick={props.onShowModal}
                className={`h-auto w-1/2`}
            ></DeleteButton>
            {props.isBannerPopup ? (
                <>
                    <EditButton
                        text={!isInput && `編集`}
                        onClick={
                            isInput
                                ? (e) => {
                                    if (props.onApply) {
                                        props.onApply(e, textInputRef);
                                        setIsInput(!isInput);
                                    }
                                }
                                : (e) => {
                                    props.onClick(e, textInputRef);
                                    setIsInput(!isInput);
                                }
                        }
                        className={"w-1/2"}
                    ></EditButton>
                    <ShowButton onClick={props.onShowClick} className={"w-1/2"}
                                text={props.image.isShow ? "Hide" : "Show"}></ShowButton>
                </>
            ) : (
                <AddButton onClick={props.onClick} className={"w-1/2"}/>
            )}
        </div>
    );
}

export function InputBuilder(props: {
    onClick: (e) => void;
    onClick1: (e) => void;
    onClick2: (e) => void;
}) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onClick}>
                    文字入力を追加
                </Button>
            </div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onClick1}>
                    数値入力を追加
                </Button>
            </div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onClick2}>
                    選択肢を追加
                </Button>
            </div>
        </div>
    );
}

export function ControlBuilder(props: {
    onAddIf: (e) => void;
    onAddFor: (e) => void;
    onAddDelay: (e) => void;
    onAddRecord: (e) => void;
}) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onAddIf}>
                    条件分岐を追加
                </Button>
            </div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onAddFor}>
                    繰り返しを追加
                </Button>
            </div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onAddDelay}>
                    時間待ちを追加
                </Button>
            </div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onAddRecord}>
                    Record
                </Button>
            </div>
        </div>
    );
}

export function MotionBuilder(props: { onClick: (e) => void }) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onClick}>
                    メンタロイド操作を追加
                </Button>
            </div>
        </div>
    );
}

export function SlideBuilder(props: { onClick: (e) => void }) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onClick}>
                    スライド
                </Button>
            </div>
            <div>
                ※スライドは[togo-a2894@appspot.gserviceaccount.com]と共有してください。
            </div>
        </div>
    );
}

export function GraphBuilder(props: { onClick: (e) => void }) {
    return (
        <div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={props.onClick}>
                    Add
                </Button>
            </div>
        </div>
    );
}

export function EnecolorBuilder(props: {
    onClick1: (arg: EnecolorItem) => void;
}) {
    const [eneColorKey, setEneColorKey] = useState<any>("");
    const eneColorName = useAtomValue(eneColorNameAtom)
    const eneColorKeys = useMemo(() =>
            JSON.parse(localStorage.getItem("enecolors") ?? '[]')
        , [eneColorName])
    const eneColorItems = useMemo(() => {
        return JSON.parse(localStorage.getItem(eneColorKey) ?? "{}")
    }, [eneColorKey])

    return (
        <div>
            <div key="type_select">
                <FormControl variant="outlined" margin='dense'>
                    <FormHelperText>Saved eneColor</FormHelperText>
                    <Select
                        value={eneColorKey}
                        onChange={(e) => {
                            setEneColorKey(e.target.value)
                        }}
                    >
                        <MenuItem key={0} value={""}>None</MenuItem>
                        {
                            eneColorKeys && eneColorKeys.map((item, index) =>
                                <MenuItem key={index + 1} value={item}>{item}</MenuItem>
                            )
                        }

                    </Select>
                </FormControl>
            </div>
            <div style={{margin: "10px"}}>
                <Button variant="contained" onClick={() => props.onClick1(eneColorItems)}>
                    Add Enecolor
                </Button>
            </div>
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `operation-tab-${index}`,
        "aria-controls": `operation-tabpanel-${index}`,
    };
}

export function BuilderSelector(props: {
    value: number;
    onChange: (e: any, newValue: number) => void;
}) {
    return (
        <AppBar position="static">
            <Tabs
                value={props.value}
                variant="scrollable"
                onChange={props.onChange}
                aria-label="operation-tab"
            >
                <Tab label="テキスト" {...a11yProps(0)} />
                <Tab label="画像" {...a11yProps(1)} />
                <Tab label="Enecolor" {...a11yProps(2)} />
                <Tab label="グラフ" {...a11yProps(3)} />
                <Tab label="入力" {...a11yProps(4)} />
                <Tab label="制御" {...a11yProps(5)} />
                <Tab label="モーション" {...a11yProps(6)} />
                <Tab label="スライド" {...a11yProps(7)} />
                <Tab label="ChatGPT" {...a11yProps(8)} />
            </Tabs>
        </AppBar>
    );
}

export function BuilderBannerPopupSelector(props: {
    value: number;
    onChange: (e: any, newValue: number) => void;
}) {
    return (
        <AppBar position="static">
            <Tabs
                value={props.value}
                onChange={props.onChange}
                aria-label="operation-tab"
            >
                <Tab label="バナー" {...a11yProps(0)} />
                <Tab label="ポップアップ" {...a11yProps(1)} />
            </Tabs>
        </AppBar>
    );
}
