import {atom} from 'jotai';
import {first} from 'lodash/array';
import _get from 'lodash/get';

const scenarioListAtom = atom<any>([]);

const firstScenarioFileNameAtom = atom<any>((get) => {
    const scs = get(scenarioListAtom);
    return _get(first(scs), 'id');
});
export {
    scenarioListAtom,
    firstScenarioFileNameAtom,
};
