import React from "react";
import {ImageItem} from "./Items";

type Props = {
    item: ImageItem
    onUpdate: (arg: any) => void
}

export const ImageComponent = ({item}: Props) => {

    return <div className={`flex flex-col gap-1`}>
        {item.url === "" ?
            <div key={1} style={{width: "100px", height: "100px"}}>画像なし</div> :
            <img key={2} src={item.url} alt={'sDataImg'} width="100px"/>

        }
    </div>


}
