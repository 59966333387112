import {v4 as uuidv4} from 'uuid';

export class DataUnit {
    id: string = uuidv4()
    type: string = "string"
    name: string = "new item"
    content: any = {}
    relation: string | null = null
};

export class DataStorage {
    id?: string = uuidv4()
    name?: string = "new storage"
    data?: DataUnit[] = []
    createdAt?: Date = new Date()
    updatedAt?: Date = new Date()
}
