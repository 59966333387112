import React, {useEffect, useState} from "react";
import {Checkbox, Dialog, FormControlLabel, Grid, Tooltip} from "@material-ui/core";
import axios from "axios/index";
import Button from "@material-ui/core/Button";
import InputCustom from "../../component/InputCustom";
import {isEmpty} from "lodash";

const arrSettings = [
    {name: "waitForFree", label: "待てば無料"},
    {name: 'freeAll', label: "全て無料"},
    {name: "purchaseAll", label: "全て有料"}
]
const path = process.env.REACT_APP_API_PREFIX
// const path = "https://mentoroid-api-stg.geniam.com"

const dataDefault = {
    cube: 0,
    basic: {
        waitForFree: false,
        freeAll: false,
        purchaseAll: false,
    },
    chapter: {
        free: [1, 15],
        purchase: 15,
        waitForFree: [0, 0]
    }
}
const BillingTypePage = () => {

    const [showDialogBilling, setShowDialogBilling] = useState(false)
    const [listScenarios, setListScenarios] = useState([])
    const [dataBillingRule, setDataBillingRule]: any = useState(dataDefault)
    const [formDialog, setFormDialog]: any = useState({})
    const [itemBillSetting, setItemBillSetting]: any = useState({})

    const handleBillingRule = async (item) => {
        try {
            setItemBillSetting(item)
            const res = await getDetailScenario(item?.title)
            if (res?.status === 200)
                setShowDialogBilling(true)

        } catch (err) {
            console.log(err)
        }
    }

    const handleSubmitBilling = async (type) => {
        if (!itemBillSetting?.title) return
        try {
            await axios.post(path + '/v2/scenarios/' + itemBillSetting?.title + '/setting', formatData(type))
            setShowDialogBilling(false)
        } catch (e) {
            console.log(e);
        }
    }

    const handleSubmitClearBilling = async (type) => {
        if (!itemBillSetting?.title) return
        try {
            await axios.post(path + '/v2/scenarios/' + itemBillSetting?.title + '/setting', clearData(type))
            setShowDialogBilling(false)
        } catch (e) {
            console.log(e);
        }
    }
    const handleChange = (val) => {
        let obj = arrSettings.find(o => o.name === Object.keys(val)[0]);
        if (obj?.name && Object.values(val)[0]) {
            let newVal = {
                waitForFree: Object.keys(val)[0] === "waitForFree",
                freeAll: Object.keys(val)[0] === "freeAll",
                purchaseAll: Object.keys(val)[0] === "purchaseAll",
            }
            setFormDialog({
                ...formDialog,
                ...newVal,
            })
            return
        }
        setFormDialog({
            ...formDialog,
            ...val,
        })
    }

    const getListScenario = async () => {
        try {
            const scenario = await axios.get(path + '/v2/scenario/list');
            if (scenario.data.status === 200) {
                const data = scenario.data.data.scenarios
                setListScenarios(data);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getDetailScenario = async (title) => {
        try {
            const scenario = await axios.get(path + '/v2/scenarios/' + encodeURIComponent(title) + '/setting');
            if (scenario.data.status === 200) {
                const data = scenario.data.data
                if (!isEmpty(data)) {
                    setDataBillingRule(data);
                } else {
                    setDataBillingRule({
                        ...dataDefault,
                        chapter: {
                            free: [0, 0],
                            purchase: 0,
                            waitForFree: [0, 0]
                        }
                    })
                }
                return scenario.data
            }
        } catch (e) {
            console.log(e)
        }
    }

    const formatData = (type) => {
        return {
            cube: type === 1 ? formDialog.cube : dataBillingRule?.cube,
            basic: {
                waitForFree: type === 1 ? formDialog?.waitForFree : dataBillingRule?.basic?.waitForFree,
                freeAll: type === 1 ? formDialog?.freeAll : dataBillingRule?.basic?.freeAll,
                purchaseAll: type === 1 ? formDialog?.purchaseAll : dataBillingRule?.basic?.purchaseAll,
            },
            chapter: {
                free: type === 2 ? [formDialog?.freeFrom, formDialog?.freeTo] : dataBillingRule?.chapter?.free,
                purchase: type === 3 ? formDialog?.purchase : dataBillingRule?.chapter?.purchase,
                waitForFree: type === 4 ? [formDialog?.waitForFreeFrom, formDialog?.waitForFreeTo] : dataBillingRule?.chapter?.waitForFree
            }
        }
    }
    const clearData = (type) => {
        return {
            cube: type === 1 ? 0 : dataBillingRule?.cube,
            basic: {
                waitForFree: type === 1 ? 0 : dataBillingRule?.basic?.waitForFree,
                freeAll: type === 1 ? 0 : dataBillingRule?.basic?.freeAll,
                purchaseAll: type === 1 ? 0 : dataBillingRule?.basic?.purchaseAll,
            },
            chapter: {
                free: type === 2 ? [0, 0] : dataBillingRule?.chapter?.free,
                purchase: type === 3 ? 0 : dataBillingRule?.chapter?.purchase,
                waitForFree: type === 4 ? [0, 0] : dataBillingRule?.chapter?.waitForFree
            }
        }
    }

    useEffect(() => {
        if (!dataBillingRule)
            return;

        setFormDialog({
            "cube": dataBillingRule?.cube,
            "freeFrom": dataBillingRule?.chapter?.free[0],
            "freeTo": dataBillingRule?.chapter?.free[1],
            "purchase": dataBillingRule?.chapter?.purchase,
            "waitForFreeFrom": dataBillingRule?.chapter?.waitForFree[0],
            "waitForFreeTo": dataBillingRule?.chapter?.waitForFree[1],
            "freeAll": dataBillingRule?.basic?.freeAll || false,
            "purchaseAll": dataBillingRule?.basic?.purchaseAll || false,
            "waitForFree": dataBillingRule?.basic?.waitForFree || false,
        })

    }, [dataBillingRule])

    useEffect(() => {
        if (!showDialogBilling) {
            setItemBillSetting({})
        }
    }, [showDialogBilling])

    useEffect(() => {
        getListScenario().then()
    }, [])
    return (
        <Grid container className={`p-4`}>
            {
                (listScenarios.length > 0) && listScenarios.map((item: any, index) => {
                    return (
                        <Grid item xs={2} key={item?.id + index}>
                            <Grid
                                onClick={() => handleBillingRule(item)}
                                className={`m-4 bg-[#F5F5F5] rounded cursor-pointer`}
                            >
                                <img src={item?.cover || item?.chapterThumb || "./noImg.png"} alt=""
                                     className={`rounded w-full h-[200px] object-contain`}/>
                                <div className={`flex justify-between p-2`}>
                                    <Tooltip title={item?.title}>
                                        <div
                                            className={`text-base font-medium line-clamp-1 break-words`}>{item?.title}</div>
                                    </Tooltip>
                                    <img className={"mx-1"} src="./coin.svg" alt=""/>
                                </div>
                            </Grid>
                        </Grid>

                    )
                })
            }
            <Dialog
                open={showDialogBilling}
                onClose={() => setShowDialogBilling(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid container className={`pb-2 mt-2 px-3`}>
                    <Grid item xs={3} className={`flex items-center`}>課金ルール</Grid>
                    <Grid item xs={3} className={`flex items-center`}>1話あたり</Grid>
                    <Grid item xs={3} className={`flex`}>
                        <img className={`mx-2`} src="./coin.svg" alt=""/>
                        <InputCustom
                            min={0}
                            name={"cube"}
                            type={"number"}
                            value={formDialog?.cube}
                            onChange={(e: any) => handleChange({cube: Number(e.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={3} className={`flex items-center justify-center`}>
                        <Button
                            onClick={() => handleSubmitBilling(1)}
                            disabled={
                                (formDialog?.cube == dataBillingRule?.cube
                                    && formDialog?.freeAll === dataBillingRule?.basic?.freeAll
                                    && formDialog?.purchaseAll === dataBillingRule?.basic?.purchaseAll
                                    && formDialog?.waitForFree === dataBillingRule?.basic?.waitForFree)
                                || !formDialog?.cube
                            }
                            className={`!m-auto`}
                            variant="contained"
                            color="primary"
                        >
                            適用する
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className={`m-1`}>
                    <Grid item xs={3} className={`flex items-center justify-end pr-3`}>基本設定</Grid>
                    <Grid item xs={9} className={`flex items-center`}>
                        {arrSettings.map((setting) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(formDialog?.[setting.name])}
                                            onChange={(e) => handleChange({[e.target.name]: e.target.checked})}
                                            name={setting.name}
                                            color="primary"
                                        />
                                    }
                                    label={setting.label}
                                    key={setting.name}
                                />
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid container className={`my-1 px-3`}>
                    <Grid item xs={3} className={`flex items-center justify-end pr-3`}>常に無料</Grid>
                    <Grid item xs={5} className={`flex items-center`}>
                        <InputCustom
                            min={0}
                            name="freeFrom"
                            type={"number"}
                            value={formDialog?.freeFrom}
                            onChange={(e: any) => handleChange({freeFrom: Number(e.target.value)})}
                        />
                        <Grid className={`my-auto mx-3`}>~</Grid>
                        <InputCustom
                            min={0}
                            name="freeTo"
                            type={"number"}
                            value={formDialog?.freeTo}
                            onChange={(e: any) => handleChange({freeTo: Number(e.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={4} className={`flex`}>
                        <Button
                            className={`!ml-4 !mr-2`}
                            variant="contained"
                            color="primary"
                            disabled={
                                Boolean(
                                    (formDialog?.freeFrom === dataBillingRule?.chapter?.free?.[0] && formDialog?.freeTo === dataBillingRule?.chapter?.free?.[1])
                                    || formDialog?.freeFrom >= formDialog?.freeTo
                                    || !dataBillingRule?.cube)
                            }
                            onClick={() => handleSubmitBilling(2)}
                        >
                            適用する
                        </Button>
                        <Button
                            className={``}
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitClearBilling(2)}
                        >
                            クリア
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className={`my-1 px-3`}>
                    <Grid item xs={3} className={`flex items-center justify-end pr-3`}>常に有料</Grid>
                    <Grid item xs={5} className={`flex items-center`}>
                        <InputCustom
                            min={0}
                            name="purchase"
                            labelVal="最新-"
                            type={"number"}
                            value={formDialog?.purchase}
                            onChange={(e: any) => handleChange({purchase: Number(e.target.value)})}
                        />
                        <Grid className={`my-auto mx-2`}>~</Grid>
                        <InputCustom
                            readOnly={true}
                            name="purchaseTo"
                            type={"text"}
                            value={"最新"}
                        />
                    </Grid>
                    <Grid item xs={4} className={`flex`}>
                        <Button
                            className={`!ml-4 !mr-2`}
                            variant="contained"
                            color="primary"
                            disabled={
                                formDialog?.purchase === dataBillingRule?.chapter?.purchase
                                || !dataBillingRule?.cube
                            }
                            onClick={() => handleSubmitBilling(3)}
                        >
                            適用する
                        </Button>
                        <Button
                            className={``}
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitClearBilling(3)}
                        >
                            クリア
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className={`my-1 px-3 mb-4`}>
                    <Grid item xs={3} className={`flex items-center justify-end pr-3`}>常に待てば無料</Grid>
                    <Grid item xs={5} className={`flex items-center`}>
                        <InputCustom
                            name="waitForFreeFrom"
                            value={formDialog?.waitForFreeFrom}
                            type={"number"}
                            min={0}
                            onChange={(e: any) => handleChange({waitForFreeFrom: Number(e.target.value)})}
                        />
                        <Grid className={`my-auto mx-3`}>~</Grid>
                        <InputCustom
                            name="waitForFreeTo"
                            type={"number"}
                            min={0}
                            value={formDialog?.waitForFreeTo}
                            onChange={(e: any) => handleChange({waitForFreeTo: Number(e.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={4} className={`flex`}>
                        <Button
                            className={`!ml-4 !mr-2`}
                            variant="contained"
                            color="primary"
                            disabled={
                                Boolean((formDialog?.waitForFreeFrom === dataBillingRule?.chapter?.waitForFree?.[0] && formDialog?.waitForFreeTo === dataBillingRule?.chapter?.waitForFree?.[1])
                                    || formDialog?.waitForFreeFrom >= formDialog?.waitForFreeTo
                                    || !dataBillingRule?.cube)
                            }
                            onClick={() => handleSubmitBilling(4)}
                        >
                            適用する
                        </Button>
                        <Button
                            className={``}
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitClearBilling(4)}
                        >
                            クリア
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
};

export default BillingTypePage;
