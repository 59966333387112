import {ItemComponent} from "./ItemComponent";
import React from "react";
import {IfEndItem} from "./Items";

export class IfEndComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const item = this.props.item as IfEndItem
        return <div>
            ----- 条件分岐終了 -----
        </div>
    }
}
