import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import TopPage from './Pages/TopPage'
import {Toaster} from "react-hot-toast";
import axiosConfigs from "./axios/axios";

axiosConfigs();


const App = () => (
    <>
        <BrowserRouter>
            <Route path='/' component={TopPage}/>
        </BrowserRouter>
        <Toaster/>
    </>
)

export default App;
