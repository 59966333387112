import React from "react";

export class DeleteButton extends React.Component<{ text?: any, onClick: any, className?: string }> {
    render() {
        let {text, onClick, className} = this.props;
        return (
            <button
                className={`text-white cursor-pointer hover:bg-red-600 bg-red-400 border-none w-10 ${className ?? ''}`}
                onClick={onClick}>
                {text ?? '削除'}
            </button>
        )
    }
}
