import {ItemComponent} from "./ItemComponent";
import React from "react";
import {ForEndItem} from "./Items";

export class ForEndComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const item = this.props.item as ForEndItem
        return <div>
            ----- 繰り返し終了 -----
        </div>
    }
}
