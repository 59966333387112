import React from 'react';

function InputCustom({type = "text", className = '', labelVal, ...prop}: any) {
    return (
        <div className={`${labelVal ? "relative" : ''}`}>
            {
                labelVal && <span className={`absolute left-1 top-[9px]`}>{labelVal}</span>
            }
            <input
                {...prop}
                type={type}
                className={`${className} p-1 ${labelVal ? "!pl-10 !pr-10 w-8" : ''} focus:outline-0 w-full border-0 bg-[#F5F7FB] rounded h-[35px] text-center font-medium text-sm`}/>
        </div>
    );
}

export default InputCustom;
