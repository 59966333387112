import {lastScenarioAtom, lastScenarioDataAtom} from '../../atoms/lastScenario';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import React, {useMemo} from 'react';
import DeleteModal from '../../component/DeleteModal';
import toast from 'react-hot-toast';
import {
    firstScenarioFileNameAtom,
    scenarioListAtom,
} from '../../atoms/scenarioList.atom';
import axios from "axios";

export default function ScenarioDelete() {

    const beDeletedScenarioData = useAtomValue(lastScenarioDataAtom);
    const setLastScenario = useSetAtom(lastScenarioAtom);
    const firstScenarioFileName = useAtomValue(firstScenarioFileNameAtom);
    const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
    const [scenarioList, setScenarioList] = useAtom(scenarioListAtom);

    const onDelete = () => {
        // console.log(scenarioListRef.child(beDeletedScenarioData.id).fullPath);
        axios.post(`/scenario/delete/${beDeletedScenarioData.id}`).then(
            () => {
                toast.success(`${delTitle}削除しました`);
                setScenarioList(scenarioList?.filter(
                    (sc) => sc.id !== beDeletedScenarioData.id),
                );
            },
        ).catch(e => {
            alert(e.message);
        });
    };

    // useEffect(() => {
    //     console.log({firstScenarioFileName});
    //
    //     if (!scenarioList?.length || !beDeletedScenarioData)
    //         return;
    //     if (!includes(
    //         map(scenarioList, s => s?.id),
    //         beDeletedScenarioData.id)
    //     ) {
    //         console.log('set');
    //         setLastScenario(firstScenarioFileName);
    //     }
    // }, [beDeletedScenarioData, firstScenarioFileName, scenarioList]);

    const delTitle = useMemo(() => {
        if (!beDeletedScenarioData)
            return;
        return `\`${beDeletedScenarioData.title}\` (${beDeletedScenarioData.id})`;
    }, [beDeletedScenarioData]);

    return (
        <div>
            <button
                className={`bg-red-800 p-2 rounded text-white border-none hover:bg-red-900 cursor-pointer`}
                onClick={() => setShowDeleteConfirm(true)}>
                Delete
            </button>
            {
                beDeletedScenarioData && <DeleteModal
                    title={delTitle + `を削除しますか？`}
                    show={showDeleteConfirm}
                    setShow={setShowDeleteConfirm}
                    // scenario={beDeletedScenarioData}
                    onYes={() => {
                        console.log('delete');
                        console.log(beDeletedScenarioData);
                        setShowDeleteConfirm(false);
                        onDelete();
                    }}
                    onNo={() => {
                        console.log('cancel');
                        setShowDeleteConfirm(false);
                    }}
                />
            }
        </div>
    );
}
