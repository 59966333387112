import {scenarioCollRef, scenarioContentCollRef} from "../firebase/refs/scenario";
import {useEffect, useState} from 'react'
import {useCollection} from "react-firebase-hooks/firestore";
import {orderBy} from "lodash"
import {toDate} from "../common/date";

export const useScenarios = () => {
    const [scenarios, setScenarios] = useState<any>([])
    const [data, loading, error] = useCollection(scenarioCollRef())

    useEffect(() => {
        if (loading || error || !data || (!data && !loading))
            return;

        const _scenarios = data.docs.map(doc => Object.assign({}, {
            ...doc.data(),
            id: doc.id,
        }, {id: doc.id}))
        if (_scenarios?.length) {
            getContents(_scenarios).then()
        }
    }, [data, loading, error])

    const getContents = async (_scenarios) => {
        try {
            let list = await Promise.all(_scenarios.map(async item => {
                const snapshots = await scenarioContentCollRef(item.id).get()
                if (!snapshots.size)
                    return {...item, contents: null}
                const contents = snapshots.docs.map(doc => ({
                    ...doc.data(),
                    updatedAt: toDate(doc.data().updatedAt),
                    createdAt: toDate(doc.data().createdAt)
                }))
                return {...item, contents: contents}
            }))
            setScenarios(orderBy(list, "createdAt", "desc"))
        } catch (e) {
            console.log(e);
        }
    }

    return {scenarios, sceLoading: loading}
}

