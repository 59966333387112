import {DataStorage, DataUnit} from "../model/DataStructure";
import {useMemo} from "react";
import {useDefinitions} from "./definitions";
import {useAtomValue} from "jotai";
import {checkedDefinitionsIdAtom} from "../atoms/definitionsAtom";
import {isArray} from "lodash";

export function useGenUnits() {
    const {definitions} = useDefinitions()
    const checkedDefinitionsId = useAtomValue(checkedDefinitionsIdAtom)
    return useMemo(() => {
        const _units = [] as { unit: DataUnit; storage: DataStorage }[];
        if (!definitions || !isArray(checkedDefinitionsId)) return [];
        for (let id of checkedDefinitionsId) {
            const o = definitions.find((x) => x.id === id);
            if (o?.data) {
                o.data?.forEach((unit) => {
                    _units.push({unit: unit, storage: o});
                });
            }
        }
        return _units;
    }, [checkedDefinitionsId, definitions])
}
