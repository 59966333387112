type props = {
    onClick: (e: any) => void
}
const ChatgptComponent = ({onClick}: props) => {
    return (
        <div className={'w-full h-full pt-5 pl-5 '}>
            <button className={'bg-sky-200 rounded p-2 cursor-pointer border-1 border-gray-200 hover:bg-sky-300'}
                    onClick={onClick}>追加 ChatGPT
            </button>
        </div>
    )
}

export default ChatgptComponent
