import {db} from '../app'

export const scenarioCollRef = () => {
    return db.collection('scenarios')
}

export const scenarioDocRef = (docId: string) => {
    return db.collection('scenarios').doc(docId)
}
export const scenarioContentCollRef = (docId: string) => {
    return db.collection('scenarios').doc(docId).collection('contents').orderBy('index', "asc")

}
export const scenarioContentDocRef = (docId: string, contentId: string) => {
    return db.collection('scenarios').doc(docId).collection('contents').doc(contentId)
}
