import {ItemComponent} from "./ItemComponent";
import {MenuItem, Select} from "@material-ui/core";
import React from "react";
import {MotionItem} from "./Items";

export class MotionComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const item = this.props.item as MotionItem
        const data = [
            {
                name: "メンタロイド",
                id: "mentroid_name",
                choice: [
                    "エナ", "ラビック",
                ]
            },
            {
                name: "動き",
                id: "motion_type",
                choice: [
                    "そのまま",
                    "冷淡", "冷静", "固執", "批判", "探究", "研磨", "追求", "陶酔",
                    "不安", "依存", "信頼", "妄信", "愛情", "慈愛", "犠牲", "配慮",
                    "傲慢", "執着", "強引", "情熱", "憤怒", "根性", "突破", "覚悟",
                    "自由", "中毒", "勝手", "夢想", "怠惰", "楽天", "気楽", "熱中", "適当"]
            },
            {
                name: "位置",
                id: "location",
                choice: ["そのまま", "左", "中", "右"]
            },
            {
                name: "反転",
                id: "flip",
                choice: ["そのまま", "反転あり", "反転なし"]
            },
            {
                name: "出現",
                id: "show",
                choice: ["そのまま", "出現", "消える"]
            }
        ]
        return <div>
            {
                data.map(x => {
                    return <div key={x.id}>
                        {x.name}：<Select
                        value={item[x.id]}
                        onChange={(e) => {
                            item[x.id] = e.target.value as string
                            this.props.onUpdate(item)
                        }}
                    >
                        {
                            x.choice.map((s, ui) => {
                                return <MenuItem key={ui} value={s}>{s}</MenuItem>
                            })
                        }
                    </Select>
                    </div>
                })
            }
        </div>
    }
}
