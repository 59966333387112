import React from "react";

type Props = {
    text?: any,
    onClick?: any,
    className: any
}
export default function ShowButton({text, onClick, className}: Props) {

    return (
        <button
            className={`text-white cursor-pointer hover:bg-green-800 bg-green-600 border-none w-full p-2 ${className ?? ''}`}
            onClick={onClick}
        >
            {text || "Hide"}
        </button>
    )

}
