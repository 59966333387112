import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {ImageComponent} from "./ImageComponent";
import {IfEndComponent} from "./IfEndComponent";
import {ForEndComponent} from "./ForEndComponent";
import {MotionComponent} from "./MotionComponent";
import {DelayComponent} from "./DelayComponent";
import EnecolorGraphComponent from "./EnecolorGraphComponent";
import EnecolorComponent from "./EnecolorComponent";
import {getItemStyle, getListStyle} from "./ScenarioPage";
import {ChoiceItem, EnecolorItem, ForStartItem, IfStartItem, ImageItem, InputItem, Item, TextItem} from "./Items";
import {DeleteButton} from "../../component/DeleteButton";
import DeleteModal from "../../component/DeleteModal";
import TextComponent from "./TextComponent";
import InputComponent from "./InputComponent";
import ChoiceComponent from "./ChoiceComponent";
import IfStartComponent from "./IfStartComponent";
import ForStartComponent from "./ForStartComponent";
import SlideComponent from "./SlideComponent";
import RecordStartComponent from "./RecordStartComponent";
import RecordEndComponent from "./RecordEndComponent";
import {genId} from "cf-gen-id";
import {BlockExitImageComponent} from "./BlockExitImageComponent";

export class ScenarioContentBuilder extends React.Component<any, {}> {
    state = {
        showDeleteModal: false,
        deleteId: null,
        deleteType: "",
        deleteIndex: "",
    }

    constructor(props: any) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        // const items = reorder(
        //     this.props.scenario,
        //     result.source.index,
        //     result.destination.index
        // );

        const items = structuredClone(this.props.scenario);
        const srcIdx = items.findIndex(item => item.index === result.source.index)
        const desIdx = items.findIndex(item => item.index === result.destination.index)
        const [removedItem] = items.splice(srcIdx, 1);
        items.splice(desIdx, 0, removedItem);
        for (let i = 0; i < items.length; i++) {
            items[i].index = i;
        }
        this.props.updateScenario(items)
    }

    onChangeItem(idx: number, item: any) {
        const sc = this.props.scenario;
        // item.args[0].groups.forEach(gr => )
        // item.args[0].ranks
        const index = sc.findIndex(i => i.id === item.id)
        sc[index] = {...item};
        this.props.updateScenario([...sc])
    }

    handleDeleteItem(index, type) {
        if (index === null) return
        this.props.onDeleteItem(index)
        // if (type = "enecolor") loadAndDelImg()
        this.setState({showDeleteModal: false})
    }

    getItemColor(type: string) {
        if (type === "image") return "#b3e5fc"
        if (type === "text") return "#b2ebf2"
        if (type === "motion") return "#b2dfdb"
        if (type === "input") return "#f8bbd0"
        if (type === "choice") return "#ffcdd2"
        if (type === "if_start") return "#f0f4c3"
        if (type === "if_end") return "#f0f4c3"
        if (type === "for_start") return "#f0f4c3"
        if (type === "for_end") return "#f0f4c3"
        if (type === "record_start") return "#f0f4c3"
        if (type === "record_end") return "#f0f4c3"
        if (type === "delay") return "#c6f0c0"
        if (type === "slide") return "#aa9922"
        if (type === "graph") return "#ff0000"
        if (type === "enecolor_rank_img") return "#FFC0CB"
        if (type === "chatGPT") return "#74AA9C"
        if (type === "blockExitImage") return "#ff9999"
        return "#333"
    }

    render() {
        const lang = this.props.lang
        let indent = 0;
        return (
            <div style={{
                height: "100%",
                overflowY: "scroll",
            }}>
                <div style={{padding: "10px", border: "1px solid", color: "white", backgroundColor: "#3f51b5"}}>
                    シナリオ内容(Sum: {this.props.scenario?.length || 0})
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    this.props.scenario.map((item: Item) => {
                                        let idt = indent
                                        if (item.type === "if_start" || item.type === "for_start") ++indent
                                        else if (item.type === "if_end" || item.type === "for_end") {
                                            --indent
                                            idt = indent
                                        }

                                        return <Draggable key={`item_${item.index}`} draggableId={`${item.index}`}
                                                          index={item.index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        ),
                                                        marginLeft: idt * 20,
                                                        border: "solid 3px",
                                                        borderRadius: "10px",
                                                        borderColor: this.getItemColor(item.type)
                                                    }}
                                                >
                                                    <div style={{
                                                        color: "#222",
                                                        backgroundColor: this.getItemColor(item.type),
                                                        borderRadius: "5px",
                                                        margin: "-10px",
                                                        marginBottom: "10px",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        width: "min-content"
                                                    }}>
                                                        {item.type}
                                                    </div>
                                                    <div style={{marginTop: "10px"}}>
                                                        {
                                                            item.type === "text" ?
                                                                <TextComponent key={item.index} item={item as TextItem}
                                                                               lang={lang}
                                                                               onUpdate={(s) => {
                                                                                   this.onChangeItem(item.index, s)
                                                                               }}
                                                                               enecolorGroupData={this.props.enecolorGroupData}
                                                                /> :
                                                                item.type === "image" ?
                                                                    <ImageComponent key={item.index}
                                                                                    item={item as ImageItem}
                                                                                    onUpdate={(s) => {
                                                                                        this.onChangeItem(item.index, s)
                                                                                    }}/> :
                                                                    item.type === "enecolor_rank_img" ?
                                                                        <EnecolorComponent key={item.index}
                                                                                           item={item as EnecolorItem}
                                                                                           onUpdate={(s) => {
                                                                                               this.onChangeItem(item.index, s)
                                                                                           }}
                                                                            // enecolorGroupData={this.props.enecolorGroupData}
                                                                        /> :
                                                                        item.type === "graph" ?
                                                                            <EnecolorGraphComponent key={item.index}/> :
                                                                            item.type === "blockExitImage" ?
                                                                                <BlockExitImageComponent
                                                                                    key={item.index}/> :item.type === "input" ?
                                                                                <InputComponent key={item.index}
                                                                                                item={item as InputItem}
                                                                                                onUpdate={(s) => {
                                                                                                    this.onChangeItem(item.index, s)
                                                                                                }}
                                                                                /> :
                                                                                item.type === "choice" ?
                                                                                    <ChoiceComponent key={item.index}
                                                                                                     item={item as ChoiceItem}
                                                                                                     onUpdate={(s) => {
                                                                                                         this.onChangeItem(item.index, s)
                                                                                                     }}
                                                                                    /> :
                                                                                    item.type === "if_start" ?
                                                                                        <IfStartComponent
                                                                                            key={item.index}
                                                                                            item={item as IfStartItem}
                                                                                            onUpdate={(s) => {
                                                                                                this.onChangeItem(item.index, s)
                                                                                            }}
                                                                                        /> :
                                                                                        item.type === "if_end" ?
                                                                                            <IfEndComponent
                                                                                                key={item.index}
                                                                                                item={item}/> :
                                                                                            item.type === "for_start" ?
                                                                                                <ForStartComponent
                                                                                                    key={item.index}
                                                                                                    item={item as ForStartItem}
                                                                                                    onUpdate={(s) => {
                                                                                                        this.onChangeItem(item.index, s)
                                                                                                    }}
                                                                                                /> :
                                                                                                item.type === "for_end" ?
                                                                                                    <ForEndComponent
                                                                                                        key={item.index}
                                                                                                        item={item}/> :
                                                                                                    item.type === "motion" ?
                                                                                                        <MotionComponent
                                                                                                            key={item.index}
                                                                                                            item={item}
                                                                                                            onUpdate={(s) => {
                                                                                                                this.onChangeItem(item.index, s)
                                                                                                            }}
                                                                                                        /> :
                                                                                                        item.type === "delay" ?
                                                                                                            <DelayComponent
                                                                                                                key={item.index}
                                                                                                                item={item}
                                                                                                                onUpdate={(s) => {
                                                                                                                    this.onChangeItem(item.index, s)
                                                                                                                }}
                                                                                                            /> : item.type === "record_start" ?
                                                                                                                <RecordStartComponent
                                                                                                                    key={item.index}/> :
                                                                                                                item.type === "record_end" ?
                                                                                                                    <RecordEndComponent
                                                                                                                        key={item.index}/> :
                                                                                                                    item.type === "slide" ?
                                                                                                                        <SlideComponent
                                                                                                                            key={item.index}
                                                                                                                            item={item}
                                                                                                                            onUpdate={(s) => {
                                                                                                                                this.onChangeItem(item.index, s)
                                                                                                                            }}
                                                                                                                        /> : item.type === "chatGPT" ?
                                                                                                                            <div
                                                                                                                                className={'w-full h-5'}></div>
                                                                                                                            :
                                                                                                                            <div>invalid</div>
                                                        }
                                                    </div>
                                                    <button
                                                        className={'absolute right-0 bottom-0 bg-sky-200 hover:bg-sky-300 rounded px-2 cursor-pointer text-base border border-gray-300'}
                                                        onClick={() => {
                                                            const _item = structuredClone(item)
                                                            _item.id = genId({prefix: "cont_", size: 10})
                                                            _item.index = this.props.contentIndex
                                                            this.props.updateScenario([...this.props.scenario, _item])
                                                        }}
                                                    >
                                                        copy
                                                    </button>
                                                    <div className={'absolute right-0 top-0'}>
                                                        {/*<Button variant="contained"*/}
                                                        {/*        onClick={(e) => this.props.onDeleteItem(index, item)}*/}
                                                        {/*        style={{*/}
                                                        {/*            fontSize: "5px"*/}
                                                        {/*        }}>削除</Button>*/}

                                                        <DeleteButton
                                                            onClick={() => {
                                                                this.setState({showDeleteModal: true})
                                                                this.setState({deleteId: item.id})
                                                                this.setState({deleteIndex: item.index})
                                                                this.setState({deleteType: item.type})
                                                            }}
                                                            className={'rounded'}
                                                        />
                                                        <DeleteModal
                                                            title={`Do you want to delete content  ${this.state.deleteType} number ${this.state.deleteIndex}？`}
                                                            show={this.state.showDeleteModal}
                                                            setShow={() => this.setState({showDeleteModal: true})}
                                                            onYes={() => this.handleDeleteItem(this.state.deleteId, this.state.deleteType)}
                                                            onNo={() => {
                                                                this.setState({showDeleteModal: false})
                                                            }}
                                                        ></DeleteModal>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )
                        }
                    </Droppable>
                </DragDropContext>
            </div>
        )
            ;
    }
}
