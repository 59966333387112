import {useEffect} from "react";
import {useScenarios} from "./scenarios";
import {useAtom} from "jotai";
import {checkedDefinitionsIdAtom} from "../atoms/definitionsAtom";

export default function useCheckedDefinitions(sid: string) {
    const [checkedDefinitionsId, setCheckDefinitionsId] = useAtom(checkedDefinitionsIdAtom)
    const {scenarios} = useScenarios()

    useEffect(() => {
        if (scenarios.length === 0) return
        const scenario = scenarios.find(s => s.id === sid)
        setCheckDefinitionsId(scenario?.checkedDefinitionsId || [])
    }, [scenarios, sid])

    return {checkedDefinitionsId, setCheckDefinitionsId}
}
