import firebase from "firebase";
import {db} from "./app";

export const bannersRef = db.collection("banners");
export const popupsRef = db.collection("popups");

class FireStore {
    async putFile(
        fileName: any,
        url: string,
        pageUrl: string,
        ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) {
        ref
            .doc()
            .set({name: fileName, url, pageUrl, createdAt: new Date()})
            .catch((err) => console.error(err));
    }

    async delFile(
        fileId: any,
        ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) {
        ref
            .doc(fileId)
            .delete()
            .catch((err) => console.error(err));
    }

    async updateFile(
        fileId: any,
        ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
        pageUrl: string,
    ) {
        ref
            .doc(fileId)
            .update({pageUrl: pageUrl, updatedAt: new Date()})
            .catch((err) => console.error(err));
    }

    async updateIsShowFile(
        fileId: string,
        ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
        isShow: Boolean
    ) {
        ref
            .doc(fileId)
            .update({isShow: !isShow, updatedAt: new Date()})
            .catch((err) => console.error(err));
    }
}


export const imgFireStore = new FireStore();
