import React, {useState} from "react";
import {DataStorage, DataUnit} from "../../model/DataStructure";
import {TextField} from "@material-ui/core";
import {NormalButton} from "../../component/NormalButton";
import {DeleteButton} from "../../component/DeleteButton";
import {AddButton} from "../../component/AddButton";
import {DraggableListItem} from "./DraggableListItem";
import DeleteModal from "../../component/DeleteModal";
import {useModal} from "../../hooks/useModal";
import {createDefinitions, updateAllDefinitions, useDefinitions} from "../../hooks/definitions";

export function Page() {
    const [deployMessage, setDeployMessage] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<string>("読み込み");
    const {isDeleteModal, toggleDeleteModal} = useModal();
    const [delStorageId, setDelStorageId] = useState<any>(null);
    const [delUnitId, setDelUnitId] = useState<any>(null);
    const [updateList, setUpdateList] = useState<any>([])
    const [delList, setDelList] = useState<any>([])
    const {definitions, setDefinitions} = useDefinitions()

    // useEffect(() => {
    //     store.set("storages")(JSON.parse(JSON.stringify(definitions)))
    // }, [definitions])

    function delData(si: number, di: number, dt: any) {
        const s = [...definitions]
        s[si].data = s[si].data.filter((x, i) => i !== di)
        const filter = updateList.filter((item) => {
            return item.id !== s[si].id
        })
        setUpdateList([...filter, s[si]])
        setDefinitions(s)
    }

    function addData(idx: number, d: DataUnit) {
        const s = [...definitions]
        s[idx].data.push(d)
        setDefinitions(s)
        if (definitions?.length && s?.length) {
            let find = definitions.find(item => item?.id === s[idx]?.id)
            if (find) {
                const filter = updateList.filter((item) => {
                    return item.id !== s[idx].id
                })
                setUpdateList([...filter, s[idx]])
            }
        }
    }

    function updateStorage(idx: number, s: DataStorage) {
        const st = [...definitions]
        st[idx] = s;
        setDefinitions(st)
        if (definitions?.length) {
            let find = definitions.find(item => item?.id === st[idx]?.id)
            if (find)
                setUpdateList([...updateList, st[idx]])
        }
    }

    function addStorage(s: DataStorage) {
        const st = [...definitions]
        st.push(s)
        setDefinitions(st)
    }

    const saveScript = async () => {
        try {
            let updates = updateList
            let creates = [] as DataStorage[]
            if (delList?.length) {
                if (updateList?.length) {
                    updates = updateList.filter(value => {
                        let result = delList.find(item => item === value?.id)
                        if (!result)
                            return value
                    })
                }
            }
            const st = [...definitions]
            if (st?.length) {
                if (definitions?.length) {
                    creates = st.filter(value => {
                        let find = definitions.find(item => item?.id === value?.id)
                        if (!find)
                            return value
                    })
                } else {
                    creates = st
                }
            }
            await updateAllDefinitions({listUpdates: updates, listDeletes: delList}).then(() => {
                setUpdateList([]);
                setDelList([])
            })

            if (creates?.length)
                await createDefinitions(creates)
        } catch (e) {
            console.log(e);
        }
        // let obj = store.get("storages")
        // let metadata = {
        //     customMetadata: {}
        // }
        // setDeployMessage('保存中');
        // setSending(true)
        // definitionStore.save(JSON.stringify(obj), metadata).then(() => {
        //     setDeployMessage('完了');
        //     setSending(false)
        // }).catch(err => {
        //     setDeployMessage('エラー');
        //     setSending(false)
        //     console.log(err)
        // }).finally(() => {
        //     setDeployMessage('');
        //     setSending(false)
        // })
    }

    function onDeleteStorage(idx: number) {
        let st: DataStorage[] = []
        let find = ''
        st = definitions.filter((s, si) => {
            if (si != idx)
                return s
            find = s?.id
        })
        setDefinitions(st)
        setDelList([...delList, find])
    }

    function handleDeleteStorage(si: number) {
        setDelStorageId(si);
        toggleDeleteModal(true)
    }

    function handleDeleteUnit(si: number, di: number, dt: any) {
        setDelStorageId(si)
        setDelUnitId(di)
        toggleDeleteModal(true)
    }

    function handleYes() {
        if (delUnitId !== null && delStorageId !== null)
            delData(delStorageId, delUnitId, null)
        else
            onDeleteStorage(delStorageId)
        setDelUnitId(null)
        setDelStorageId(null)
        toggleDeleteModal(false);
    }

    return <div className={'w-full'}>
        {/*<MyComponent/>*/}
        <div className={'flex flex-col flex-wrap gap-3 w-full'}>
            <div className={'flex flex-wrap m-2 justify-between'}>
                <div className={'flex gap-2'}>
                    {/*<Button variant="contained"*/}
                    {/*        onClick={(e) => loadScript()}*/}
                    {/*>{reloadMessage}</Button>*/}
                    <NormalButton
                        onClick={(e) => addStorage(new DataStorage())}
                        text={'+'}
                        className={'w-12'}
                    />
                </div>
                <div>
                    <NormalButton
                        onClick={(e) => saveScript()}
                        text={deployMessage ? '保存中...' : '保存'}
                        className={'bg-green-800 w-20'}
                    />
                </div>

            </div>
            <div className={'flex gap-1'}>
                {
                    definitions.map((definition, si) => {
                        return (
                            <div className={'flex flex-col'}
                                 key={definition.id}>
                                <div className={'bg-gray-800 flex'}>
                                    <div className={'flex-1'}>
                                        <TextField
                                            id="outlined-name"
                                            // label="ストレージ名"
                                            value={definition.name}
                                            InputProps={{
                                                style: {
                                                    color: "white"
                                                }
                                            }}
                                            onChange={(e) => {
                                                definition.name = e.target.value as string
                                                updateStorage(si, definition)
                                            }}
                                            margin="none"
                                            variant="outlined"
                                            size="small"
                                        />
                                    </div>
                                    {/*delete button*/}
                                    <div>
                                        <DeleteButton
                                            onClick={(e) => handleDeleteStorage(si)}
                                        />
                                    </div>
                                </div>
                                <DraggableListItem
                                    storage={definition}
                                    onDeleteItem={(idx: number, dt: any) => handleDeleteUnit(si, idx, dt)}
                                    updateStorage={(s: any) => updateStorage(si, s)}/>
                                <AddButton
                                    onClick={(e) => addData(si, new DataUnit())}
                                />
                            </div>

                        )
                    })
                }
            </div>
        </div>
        <DeleteModal
            title={`削除しますか？`}
            show={isDeleteModal}
            setShow={toggleDeleteModal}
            onYes={handleYes}
            onNo={() => {
                toggleDeleteModal(false);
            }}
        ></DeleteModal>
    </div>
}
