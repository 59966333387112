import {ItemComponent} from "./ItemComponent";
import {TextField} from "@material-ui/core";
import React from "react";
import {DelayItem} from "./Items";

export class DelayComponent extends ItemComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const item = this.props.item as DelayItem

        return <div>
            <TextField
                style={{width: "100px"}}
                label="秒数"
                value={item.seconds}
                onChange={(e) => {
                    let s = e.target.value
                    item.seconds = parseFloat(s);
                    this.props.onUpdate(item)
                }}
                type="number"
                margin="none"
                variant="outlined"
                size="small"
            />
        </div>
    }
}
