import {definitionsCollRef} from "../firebase/refs/definitions";
import {useEffect, useState} from 'react'
import axios from "axios";
import {useCollection} from "react-firebase-hooks/firestore";
import {orderBy} from 'lodash'
import {toDate} from '../common/date'
import {DataStorage} from "../model/DataStructure";

const path = process.env.REACT_APP_API_PREFIX
const updateAll = path + '/definition/updateAll'
const create = path + '/definition/create'
export const useDefinitions = () => {
    const [definitions, setDefinitions] = useState<DataStorage[]>([])
    const [data, loading, error] = useCollection(definitionsCollRef())
    useEffect(() => {
        if (!data || loading || error || (!data && !loading))
            return;

        const _definitions = data.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
        if (_definitions?.length)
            setDefinitions(orderBy(_definitions, (e => toDate(e.createdAt)), 'asc'))

    }, [data, loading, error])

    return {definitions, setDefinitions, defLoading: loading}
}

export const updateAllDefinitions = async (data) => {
    try {
        return await axios.post(updateAll, data)
    } catch (e) {
        console.log(e);
    }
}

export const createDefinitions = async (data) => {
    try {
        return await axios.post(create, data)
    } catch (e) {
        console.log(e);
    }
}
