import {Checkbox, FormControl, MenuItem, Select, TextField} from "@material-ui/core";
import React from "react";
import {InputItem} from "./Items";
import {useGenUnits} from "../../hooks/useGenUnits";

type Props = {
    item: InputItem
    onUpdate: (arg: InputItem) => void
}

export default function InputComponent({item, onUpdate}: Props) {
    const units = useGenUnits()
    return <div>
        <FormControl variant="outlined" margin='dense'>
            <Select
                value={JSON.stringify({
                    targetId: item.target_id,
                    input_data: item.input_data
                })}
                onChange={(e) => {
                    const targetId = JSON.parse(e.target.value as string).targetId;
                    const input_data = JSON.parse(e.target.value as string).input_data;
                    item.target_id = targetId;
                    item.input_data = input_data;
                    onUpdate(item)
                }}
            >
                {
                    units.map((unit, ui) => {
                        return <MenuItem key={ui}
                                         value={JSON.stringify({
                                                 targetId: unit.unit.id,
                                                 input_data: `${unit.storage.name}:${unit.unit.name}`
                                             }
                                         )}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
        <Checkbox
            id="is_active"
            checked={item.is_enecolor_bar === true}
            onChange={(e) => {
                item.is_enecolor_bar = (e.target.checked === true)
                onUpdate(item)
            }}
        />エネカラーバー
        {
            item.input_type == "number" ?
                <div style={{margin: "10px"}}>
                    <TextField
                        style={{width: "100px"}}
                        label="最小値"
                        value={item.minValue}
                        onChange={(e) => {
                            let s = e.target.value
                            item.minValue = parseFloat(s);
                            onUpdate(item)
                        }}
                        type="number"
                        margin="none"
                        variant="outlined"
                        size="small"
                    />
                    〜
                    <TextField
                        style={{width: "100px"}}
                        label="最大値"
                        value={item.maxValue}
                        onChange={(e) => {
                            let s = e.target.value
                            item.maxValue = parseFloat(s);
                            onUpdate(item)
                        }}
                        margin="none"
                        variant="outlined"
                        size="small"
                    />
                </div>
                : null
        }
    </div>
}
