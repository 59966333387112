import axios from 'axios'

export const getDeeplink = async ({
                                      scenarioId = "",
                                      title = "",
                                      contentId = "",
                                      chapterId = ""
                                  }) => {
    try {
        const path = process.env.REACT_APP_API_PREFIX
        const result = await axios.post(path + '/deeplink', {
            scenarioId,
            title,
            contentId,
            chapterId
        })
        return result?.data?.data ?? ""
    } catch (err) {
        console.log(err);
        return ""
    }
}

export const deeplink = async ({
                                   scenarioId = "",
                                   title = ""
                               }) => {
    try {
        const path = process.env.REACT_APP_API_PREFIX
        const result = await axios.post(path + '/deeplink', {
            scenarioId,
            title
        })
        return result?.data?.data?.link ?? ""
    } catch (err) {
        console.log(err);
        return ""
    }
}
