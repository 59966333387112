import React from 'react';
import image from '../../img/graph.png'

type Props = {}

function EnecolorGraphComponent({}: Props) {
    return (
        <img className={`w-24 aspect-square`}
             src={image}
             alt={'graph'}/>
    );
}

export default EnecolorGraphComponent;
