import {FormControl, FormHelperText, MenuItem, Select, TextField} from "@material-ui/core";
import React from "react";
import {getArgs} from "./ScenarioPage";
import {ForStartItem} from "./Items";
import {useGenUnits} from "../../hooks/useGenUnits";

type Props = {
    item: ForStartItem
    onUpdate: (arg: ForStartItem) => void
}
export default function ForStartComponent({item, onUpdate}: Props) {
    const units = useGenUnits()

    return <div>
        <TextField
            size="small"
            id="outlined-name"
            label="終了条件式"
            value={item.condition}
            onChange={(e) => {
                item.condition = e.target.value as string
                const newArgs = getArgs(item.condition);
                newArgs.forEach(a => {
                    const p = item.args.find(b => a.name == b.name);
                    if (p) {
                        a.target_id = p.target_id;
                    }
                })
                item.args = newArgs;
                onUpdate(item);
            }}
            margin="none"
            variant="outlined"
        />
        <div>
            <FormControl variant="outlined" margin='dense'>
                <FormHelperText>カウンタ</FormHelperText>
                <Select
                    value={item.counter.target_id}
                    onChange={(e) => {
                        item.counter.target_id = e.target.value as string
                        onUpdate(item)
                    }}
                >
                    {
                        units.map((unit, ui) => {
                            return <MenuItem key={ui}
                                             value={unit.unit.id}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <div> --- 引数 ---</div>
            {
                item.args ? item.args.map((arg, argi) => {
                        return <div key={argi}>
                            <FormControl variant="outlined" margin='dense'>
                                <FormHelperText>{arg.name}</FormHelperText>
                                <Select
                                    value={arg.target_id}
                                    onChange={(e) => {
                                        item.args[argi].target_id = e.target.value as string
                                        onUpdate(item)
                                    }}
                                >
                                    {
                                        units.map((unit, ui) => {
                                            return <MenuItem key={ui}
                                                             value={unit.unit.id}>{unit.storage.name}:{unit.unit.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    })
                    : null
            }
        </div>
    </div>
}
